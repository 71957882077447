import axios from "axios";

export default {
    namespaced: true,
    state: () => {
        return {};
    },
    getters: {},
    mutations: {},
    actions: {
        async getAccount({ commit }) {
            try {
                const response = await axios.get(
                    `${this._vm.$agencyAPI}/account?include=rolePermission`
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        async updateAccount({ commit }, payload) {
            try {
                const response = await axios.put(
                    `${this._vm.$agencyAPI}/account`,
                    payload
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        async updatePassword({ commit }, password) {
            try {
                const response = await axios.put(
                    `${this._vm.$agencyAPI}/account/password`,
                    { password: password }
                );

                return response.data;
            } catch (error) {
                throw error;
            }
        },
        checkPermission({ commit, state }, permission) {
            let permissions = JSON.parse(
                localStorage.getItem(this._vm.$permissionKey)
            );
            if (this._vm._.get(permissions, permission)) {
                return true;
            } else {
                return false;
            }
        },
        async setAccountPermission({ commit, state }) {
            try {
                let response = await axios.get(
                    `${this._vm.$agencyAPI}/account/permission`
                );
                await localStorage.setItem(
                    this._vm.$permissionKey,
                    JSON.stringify(response.data)
                );
                return null;
            } catch (error) {
                throw error;
            }
        },
        async getAccountPermission({ commit, state }) {
            try {
                let permission = await JSON.parse(
                    localStorage.getItem(this._vm.$permissionKey)
                );
                return permission;
            } catch (error) {
                throw error;
            }
        },
        async sendResetPasswordEmail({ commit }, payload) {
            try {
                let response = await axios.post(
                    `${this._vm.$apiURL}/reset-password`,
                    payload
                );
                return response;
            } catch (error) {
                throw error;
            }
        }
    }
};
