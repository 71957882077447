import axios from "axios";

export default {
  namespaced: true,
  state: () => {
    return {};
  },
  getters: {},
  mutations: {},
  actions: {
    // ======================== Main CRUD  ========================
    async getAllAgencyUserRoles(
      { commit },
      { queries = {}, page = 1, perPage = 15 }
    ) {
      try {
        let include = "";
        let response = await axios.get(
          `${this._vm.$agencyAPI}/user-roles` +
            `?page=${page}&limit=${perPage}&include=${include}`,
          { params: queries }
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    async getAgencyUserRole({ commit }, { id }) {
      let include = "";

      try {
        let response = await axios.get(
          `${this._vm.$agencyAPI}/user-roles/${id}?include=${include}`
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    async createAgencyUserRole({ commit }, payload) {
      try {
        const response = await axios.post(
          `${this._vm.$agencyAPI}/user-roles`,
          payload
        );

        return response.data;
      } catch (error) {
        throw error;
      }
    },
    async updateAgencyUserRole({ commit }, { id, payload }) {
      try {
        const request = await axios.put(
          `${this._vm.$agencyAPI}/user-roles/${id}`,
          payload
        );

        return request.data;
      } catch (error) {
        throw error;
      }
    },
    async deleteAgencyUserRole({ commit }, id) {
      try {
        const request = await axios.delete(
          `${this._vm.$agencyAPI}/user-roles/${id}`
        );

        return true;
      } catch (error) {
        return false;
      }
    },
    async getPermission() {
      try {
        const response = await axios.get(`${this._vm.$agencyAPI}/permission`);

        return response.data;
      } catch (error) {
        throw error;
      }
    },
    async getPermissionTemplate() {
      try {
        const response = await axios.get(
          `${this._vm.$commonAPI}/permission/agency`
        );

        return response.data;
      } catch (error) {
        throw error;
      }
    }
  }
};
