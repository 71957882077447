import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

import login from "./login/index";

import PropertyBooking from "@/store/propertyBooking/index";

import ManagePrivateRequest from "@/store/managePrivateRequest/index";
import ManageRequest from "@/store/manageRequest/index";
import ManagePartnership from "@/store/managePartnership/index";
import PartnershipPropertyListing from "@/store/partnershipPropertyListing/index";

import ManageProperty from "@/store/manageProperty/index";
import ManageAccount from "@/store/manageAccount/index";
import Appointment from "@/store/appointment/index";

import ManageCountries from "@/store/manageCountries/index";
import ManageStates from "@/store/manageStates/index";
import ManageCities from "@/store/manageCities/index";
import ManageAreas from "@/store/manageAreas/index";
import manageBuildings from "@/store/manageBuildings/index";

import AgencyProfile from "@/store/agencyProfile/index";
import ManageAgencyUsers from "@/store/manageAgencyUsers/index";
import ManageAgencyUserRole from "@/store/manageAgencyUserRole/index";
import ManageBranch from "@/store/manageBranch/index";

import ManageFloorPlanComments from "@/store/manageFloorPlanComments/index";
import ManageFloorPlanVideos from "@/store/manageFloorPlanVideos/index";

import Sidebar from "@/store/misc/sidebar";
import Notifications from "@/store/notifications";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        loaderQueue: []
    },
    mutations: {
        setIsLoading(state, isLoading) {
            if (isLoading) {
                state.loaderQueue.push(state.loaderQueue.length + 1);
            } else {
                state.loaderQueue.pop();
            }
        }
    },
    getters: {
        getIsLoading(state) {
            if (state.loaderQueue.length > 0) {
                return true;
            }
            return false;
        }
    },
    actions: {
        async uploadImage({ commit }, imageFile) {
            try {
                const form = new FormData();
                form.append("file", imageFile);

                const request = await axios.post(`${this._vm.$imageAPI}`, form);

                return request.data;
            } catch (error) {
                this._vm.$reportError(error, "Upload Image");
                throw error;
            }
        },
        async uploadWatermarkImage({ commit }, imageFile) {
            try {
                const form = new FormData();
                form.append("file", imageFile);
                form.append("watermark", true);

                const request = await axios.post(`${this._vm.$imageAPI}`, form);
                return request.data;
            } catch (error) {
                this._vm.$reportError(error, "Upload Watermark Image");
                throw error;
            }
        }
    },
    modules: {
        propertyBooking: PropertyBooking,
        managePrivateRequest: ManagePrivateRequest,
        manageRequest: ManageRequest,
        managePartnership: ManagePartnership,
        partnershipPropertyListing: PartnershipPropertyListing,
        manageProperty: ManageProperty,
        manageAccount: ManageAccount,
        appointment: Appointment,

        manageCountries: ManageCountries,
        manageStates: ManageStates,
        manageCities: ManageCities,
        manageAreas: ManageAreas,
        manageBuildings: manageBuildings,

        login: login,
        agencyProfile: AgencyProfile,
        manageAgencyUsers: ManageAgencyUsers,
        manageAgencyUserRole: ManageAgencyUserRole,
        manageBranch: ManageBranch,

        manageFloorPlanComments: ManageFloorPlanComments,
        manageFloorPlanVideos: ManageFloorPlanVideos,

        sidebar: Sidebar,

        Notifications
    }
});
