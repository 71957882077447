import axios from "axios";

export default {
  namespaced: true,
  state: () => {
    return {};
  },
  getters: {},
  mutations: {},
  actions: {
    // ======================== Main CRUD  ========================
    async getAgents(
      { commit },
      {
        queries = {},
        page = 1,
        perPage = 15,
        include = "agency,agencyBranch,rolePermission"
      }
    ) {
      try {
        let response = await axios.get(
          `${this._vm.$commonAPI}/agency-users` +
            `?page=${page}&limit=${perPage}&include=${include}`,
          { params: queries }
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    async getAgencyUsers(
      { commit },
      {
        queries = {},
        page = 1,
        perPage = 15,
        include = "agency,agencyBranch,rolePermission"
      }
    ) {
      try {
        let response = await axios.get(
          `${this._vm.$agencyAPI}/agency-users` +
            `?page=${page}&limit=${perPage}&include=${include}`,
          { params: queries }
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    async getAgencyUser({ commit }, id) {
      let include = "agency,agencyBranch,rolePermission";

      try {
        let response = await axios.get(
          `${this._vm.$agencyAPI}/agency-users/${id}?include=${include}`
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    async createAgencyUser({ commit }, payload) {
      try {
        const response = await axios.post(
          `${this._vm.$agencyAPI}/agency-users`,
          payload
        );

        return response.data;
      } catch (error) {
        throw error;
      }
    },
    async updateAgencyUser({ commit }, { id, payload }) {
      try {
        const request = await axios.put(
          `${this._vm.$agencyAPI}/agency-users/${id}`,
          payload
        );

        return request.data;
      } catch (error) {
        throw error;
      }
    },
    async deleteAgencyUser({ commit }, id) {
      try {
        const request = await axios.delete(
          `${this._vm.$agencyAPI}/agency-users/${id}`
        );

        return true;
      } catch (error) {
        return false;
      }
    },
    async setAgencyUserStatus({ commit }, { id, isActive }) {
      try {
        const response = await axios.put(
          `${this._vm.$agencyAPI}/agency-users/${id}/update-active`,
          { isActive: isActive }
        );

        return response;
      } catch (error) {
        throw error;
      }
    }
  }
};
