import moment from "moment";

export function required(value, option = { err: "This field is required" }) {
    let valid = true;

    valid = Array.isArray(value)
        ? value.length > 0
        : value !== null &&
          value !== "" &&
          value !== undefined &&
          !Object.is(value, NaN);

    return valid || option.err;
}

export function minValue(
    value,
    min,
    option = { err: `The minimum value is ${min}` }
) {
    let valid = Number(value) >= min;
    return valid || option.err;
}

export function maxValue(
    value,
    max,
    option = { err: `The maximum value is ${max}` }
) {
    let valid = Number(value) <= max;
    return valid || option.err;
}

export function minLength(
    value,
    min,
    option = { err: `The minimum length is ${min}` }
) {
    let valid = value.length >= min;
    return valid || option.err;
}

export function maxLength(
    value,
    max,
    option = { err: `The maximum length is ${max}` }
) {
    let valid = value.length <= max;
    return valid || option.err;
}

export function rangeLength(
    value,
    min,
    max,
    option = { err: `The length must be ranged from ${min} to ${max}` }
) {
    let valid = value.length >= min || value.length <= max;
    return valid || option.err;
}

export function email(value, option = { err: `Please input a correct email` }) {
    let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return regex.test(value) || option.err;
}

export function phone(
    value,
    option = { err: `Please input a correct phone number` }
) {
    let regex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g;

    return regex.test(value) || option.err;
}

export function website(value, option = { err: `Please input a correct url` }) {
    let regex = /(https?:\/\/)?(www\.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)|(https?:\/\/)?(www\.)?(?!ww)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;

    return regex.test(value) || option.err;
}

export function fax(
    value,
    option = { err: `Please input a valid fax number` }
) {
    let regex = /^(\+?\d{1,}(\s?|\-?)\d*(\s?|\-?)\(?\d{2,}\)?(\s?|\-?)\d{3,}\s?\d{3,})$/gm;

    return regex.test(value) || option.err;
}

export function password(
    value,
    option = {
        err: `Password must be minimum six characters, no whitespace included`
    }
) {
    let regex = /^[\w\S]{6,}$/;

    return regex.test(value) || option.err;
}

export function price(
    value,
    option = {
        err: `Must be a positive number, up to two decimal places.`
    }
) {
    let regex = /^\s*(?=.*[0-9])\d*(?:\.\d{1,2})?\s*$/g;

    return regex.test(value) || option.err;
}

export function positiveInteger(
    value,
    option = {
        err: `Must be a positive integer.`
    }
) {
    let regex = /^[1-9]\d*$/;

    return regex.test(value) || option.err;
}

export function year(
    value,
    option = {
        err: `Must be a valid year.`
    }
) {
    let regex = /^\d{4}$/;

    return regex.test(value) || option.err;
}

export function yyyymm(
    value,
    option = {
        err: `Must be in format yyyy/mm, eg: 2020/05`
    }
) {
    let regex = /([12]\d{3}\/(0[1-9]|1[0-2]))/;

    return regex.test(value) || option.err;
}

export function dateMin(
    value,
    min,
    option = {
        err: `The minimum date is ${min}`
    }
) {
    let valid = moment(value).diff(min, "days", true) >= 0;

    return valid || option.err;
}

export function dateMax(
    value,
    max,
    option = {
        err: `The maximum date is ${max}`
    }
) {
    let valid = moment(value).diff(max, "days", true) <= 0;

    return valid || option.err;
}

export function alphanumeric(
    value,
    option = {
        err: `Must be combination of alphabets or numerical characters.`
    }
) {
    let regex = /^[A-Za-z0-9]+$/;

    return regex.test(value) || option.err;
}
