import axios from "axios";

export default {
    namespaced: true,
    facility: () => {
        return {};
    },
    getters: {},
    mutations: {},
    actions: {
        async getAllComments(
            { commit },
            { queries = {}, page = 1, perPage = 15 }
        ) {
            try {
                let include = "agencyUser";

                let response = await axios.get(
                    `${this._vm.$agencyAPI}/floor-plan/comments` +
                        `?page=${page}&limit=${perPage}&include=${include}`,
                    { params: queries }
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        async getComment({ commit }, id) {
            try {
                let include = "";

                let response = await axios.get(
                    `${this._vm.$agencyAPI}/floor-plan/comments/${id}` +
                        `?include=${include}`
                );

                return response.data;
            } catch (error) {
                throw error;
            }
        },
        async createComment({ commit }, payload) {
            try {
                const request = await axios.post(
                    `${this._vm.$agencyAPI}/floor-plan/comments`,
                    payload
                );

                return request.data;
            } catch (error) {
                throw error;
            }
        },
        async updateComment({ commit }, { id, payload }) {
            try {
                const request = await axios.put(
                    `${this._vm.$agencyAPI}/floor-plan/comments/${id}`,
                    payload
                );

                return request.data;
            } catch (error) {
                throw error;
            }
        },
        async approveComment({ commit }, id) {
            try {
                const response = await axios.put(
                    `${this._vm.$agencyAPI}/floor-plan/comments/${id}/status`,
                    {
                        status: "Approval"
                    }
                );

                return response.data;
            } catch (error) {
                throw error;
            }
        },
        async rejectComment({ commit }, id) {
            try {
                const response = await axios.put(
                    `${this._vm.$agencyAPI}/floor-plan/comments/${id}/status`,
                    {
                        status: "Reject"
                    }
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        async deleteComment({ commit }, id) {
            try {
                const request = await axios.delete(
                    `${this._vm.$agencyAPI}/floor-plan/comments/${id}`
                );

                return request;
            } catch (error) {
                throw error;
            }
        }
    }
};
