<template>
  <div>
    <div class="fd-modal__content-wrapper" :class="wrapperCSS">
      <modal-header v-if="$slots.header">
        <slot name="header"></slot>
      </modal-header>
      <modal-body v-if="$slots.body">
        <slot name="body"></slot>
      </modal-body>
      <template v-else>
        <slot></slot>
      </template>
      <modal-footer v-if="$slots.footer">
        <slot name="footer"></slot>
      </modal-footer>
    </div>
  </div>
</template>

<script>
import ModalHeader from "@/components/GlobalComponents/ModalBox/ModalHeader";
import ModalBody from "@/components/GlobalComponents/ModalBox/ModalBody";
import ModalFooter from "@/components/GlobalComponents/ModalBox/ModalFooter";

export default {
  components: {
    ModalHeader,
    ModalBody,
    ModalFooter
  },
  mixins: [],
  props: {
    fullscreen: {
      type: Boolean,
      default: false
    },
    wrapperCSS: {
      type: String,
      default: ""
    }
  },
  data: function () {
    return {};
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss">
</style>
