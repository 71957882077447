import isFunction from "lodash/isFunction";
import { required } from "@/components/GlobalComponents/FormComponents/Validator/rules";

export default {
    components: {},
    mixins: [],
    props: {
        validators: {
            type: Array,
            default: () => []
        },
        skipOptionalValidator: {
            type: Boolean,
            default: true
        },
        validateFn: {
            default: null
        }
    },
    data: function () {
        return {
            hasError: false,
            errorMessage: ""
        };
    },
    computed: {
        isRequired() {
            if (this.validators.length > 0) {
                if (this.hasRequired() || this.required) {
                    return true;
                }
            } else if (this.required) {
                return true;
            }

            return false;
        }
    },
    watch: {},
    created: function () {},
    beforeDestroy: function () {},
    mounted: function () {},
    methods: {
        validate() {
            if (this.validateFn != null) {
                // Run custom validate function if not null
                try {
                    if (!this.validateFn()) {
                        this.hasError = true;
                        return false;
                    }
                } catch (error) {
                    this.hasError = true;
                    this.errorMessage = error;
                    return false;
                }
            }
            return this.runValidators();
        },

        /**
         * Run the array of validators
         * @returns {Boolean}
         */
        runValidators() {
            let valid = true;

            // If has validator
            if (this.validators.length > 0) {
                // Execute each validation function
                for (let key in this.validators) {
                    let validator = this.validators[key];

                    // Check if validator given is a function
                    if (!isFunction(validator)) {
                        throw `Validator: ${validator} must be a function`;
                    }

                    if (!this.skipOptionalValidator) {
                        if (!this.runValidator(validator)) {
                            valid = false;
                            break;
                        }
                        continue;
                    }

                    // Validate field only if it's required or filled.
                    if (!this.isRequiredOrFilled()) {
                        valid = true;
                        break;
                    }

                    if (!this.runValidator(validator)) {
                        valid = false;
                        break;
                    }
                }
                return valid;
            }
            return valid;
        },

        /**
         * Run single validator function
         * @param {Function} validator
         * @returns {Boolean}
         */
        runValidator(validator) {
            let result = validator(this.value);
            if (result === true) {
                return true;
            } else {
                this.hasError = true;
                this.errorMessage = result;
            }
        },
        hasRequired() {
            return (
                // Cannot use function name check because the function name will be changed during production mode
                this.validators.filter((v) => Object.is(v, required)).length > 0
            );
        },
        isRequiredOrFilled() {
            return this.hasRequired() || this.value !== "";
        }
    }
};
