import axios from "axios";

export default {
    namespaced: true,
    state: () => {
        return {};
    },
    getters: {},
    mutations: {},
    actions: {
        async getAllRequests(
            { commit },
            { queries = {}, page = 1, perPage = 15 }
        ) {
            try {
                let include = "requestFrom";

                let response = await axios.get(
                    `${this._vm.$agencyAPI}/property/partnership/requests`,
                    {
                        params: {
                            ...queries,
                            include: include,
                            page: page,
                            limit: perPage
                        }
                    }
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        async getRequest({ commit }, { id }) {
            let include = "requestFrom";

            try {
                let response = await axios.get(
                    `${this._vm.$agencyAPI}/property/partnership/requests/${id}?include=${include}`
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        async createRequest({ commit }, payload) {
            try {
                const response = await axios.post(
                    `${this._vm.$agencyAPI}/property/partnership/requests`,
                    payload
                );

                return response.data;
            } catch (error) {
                throw error;
            }
        },
        async updateRequest({ commit }, { id, payload }) {
            try {
                const request = await axios.put(
                    `${this._vm.$agencyAPI}/property/partnership/requests/${id}`,
                    payload
                );

                return request.data;
            } catch (error) {
                throw error;
            }
        },
        async deleteRequest({ commit }, content) {
            commit("setIsLoading", true, { root: true });

            try {
                const request = await axios.delete(
                    `${this._vm.$agencyAPI}/property/partnership/requests/${content}`
                );

                commit("setIsLoading", false, { root: true });
                return true;
            } catch (error) {
                commit("setIsLoading", false, { root: true });
                return false;
            }
        },
        async acceptRequest({ commit }, { id, payload }) {
            try {
                const response = await axios.post(
                    `${this._vm.$agencyAPI}/property/partnership/requests/${id}/accept`,
                    payload
                );

                return response.data;
            } catch (error) {
                throw error;
            }
        }
    }
};
