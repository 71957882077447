import axios from "axios";
import { reportError } from "@/utils/error-reporting";

export class NotificationAPI {
    url;
    panelName;

    constructor(url, panelName) {
        NotificationAPI.url = url;
        NotificationAPI.panelName = panelName;
    }

    async getNotifications(queries = { page: 1, perPage: 10, seen: null }) {
        try {
            const response = await axios.get(
                `${NotificationAPI.url}/notification`,
                {
                    params: {
                        page: queries.page,
                        limit: queries.perPage,
                        seen: queries.seen
                    }
                }
            );

            return response.data;
        } catch (error) {
            reportError(
                error,
                `[${NotificationAPI.panelName}] Get Notifications`
            );
            throw error;
        }
    }
    async getNotification(id) {
        try {
            const response = await axios.get(
                `${NotificationAPI.url}/notification/${id}`
            );

            return response.data;
        } catch (error) {
            reportError(
                error,
                `[${NotificationAPI.panelName}] Get Notification (#${id})`
            );
            throw error;
        }
    }
    async markAsSeen(id) {
        try {
            const response = await axios.put(
                `${NotificationAPI.url}/notification/${id}/seen`
            );

            return response.data;
        } catch (error) {
            reportError(
                error,
                `[${NotificationAPI.panelName}] Notif. Mark as seen`
            );
            throw error;
        }
    }
}

export default NotificationAPI;
