<template>
  <div class="tab">
    <button @click="$emit('selectTab', name)" class="tab--content">
      <i v-if="iconClass" class="tab--icon" :class="iconClass"></i>
      <p>{{ label ? label : name }}</p>
    </button>
  </div>
</template>

<script>
export default {
  components: {},
  mixins: [],
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String
    },
    iconClass: {
      type: String
    }
  },
  data: function () {
    return {};
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss"></style>
