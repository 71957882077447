export default [
    /* =================================================================
     *                              Leads
     * =================================================================
     */
    {
        path: "/leads",
        name: "Leads",
        component: () => import("@/views/leads"),
        redirect: { name: "PublicLeads" },
        meta: {
            auth: true
        },
        children: [
            {
                path: "public",
                name: "PublicLeads",
                component: () => import("@/views/leads/public"),
                meta: {
                    auth: true
                }
            },
            {
                path: "following",
                name: "FollowingLeads",
                component: () => import("@/views/leads/following"),
                meta: {
                    auth: true
                }
            },
            {
                path: "agency",
                name: "AgencyLeads",
                component: () => import("@/views/leads/agency"),
                meta: {
                    auth: true,
                    permission: "lead.drop"
                }
            }
        ]
    },
    {
        path: "/leads/create",
        name: "CreateLead",
        component: () => import("@/views/leads/create/index.vue"),
        meta: {
            auth: true,
            permission: "lead.create"
        }
    },
    {
        path: "/leads/:id",
        name: "LeadDetails",
        component: () => import("@/views/leads/detail/_detail.vue"),
        meta: {
            auth: true
        }
    },
    {
        path: "/leads/:id/create-property",
        name: "CreatePropertyFromLead",
        component: () => import("@/views/leads/detail/createProperty.vue"),
        meta: {
            auth: true,
        }
    },
    /* =================================================================
     *                              ACN
     * =================================================================
     */
    {
        path: "/acn",
        name: "ACN",
        component: () => import("@/views/acn/index.vue"),
        meta: {
            auth: true
        }
    },
    {
        path: "/acn/:id",
        name: "ACNDetail",
        component: () => import("@/views/acn/detail/_detail.vue"),
        meta: {
            auth: true
        }
    },
    // Jobs
    {
        path: "/acn/jobs",
        name: "ACNJobs",
        redirect: { name: "ACNPublicJobs" },
        component: () => import("@/views/acn/jobs/index.vue"),
        meta: {
            auth: true
        },
        children: [
            {
                path: "/acn/jobs/public",
                name: "ACNPublicJobs",
                component: () => import("@/views/acn/jobs/public/index.vue"),
                meta: {
                    auth: true
                }
            },
            {
                path: "/acn/jobs/specialist",
                name: "ACNSpecialistJobs",
                component: () =>
                    import("@/views/acn/jobs/specialist/index.vue"),
                meta: {
                    auth: true
                }
            }
        ]
    },
    {
        path: "/acn/jobs/specialist/:id",
        name: "ACNSpecialistJobDetail",
        component: () => import("@/views/acn/jobs/specialist/_detail.vue"),
        meta: {
            auth: true
        }
    },
    {
        path: "/acn/jobs/public/:id",
        name: "ACNPublicJobDetail",
        component: () => import("@/views/acn/jobs/public/_detail.vue"),
        meta: {
            auth: true
        }
    },
    // Applications
    {
        path: "/acn/jobs/my-applications",
        name: "ACNMyJobApplications",
        component: () => import("@/views/acn/jobs/applications/index.vue"),
        meta: {
            auth: true
        }
    }
];
