const API = process.env.VUE_APP_API_URL.replace(/\/$/, "");
const commonAPI = `${API}/api/common`;
const adminAPI = `${API}/api/admin`;
const agencyAPI = `${API}/api/agency`;
const developerAPI = `${API}/api/project-marketing-staff`;
const imageAPI = `${API}/api/common/file-uploader/image`;
const APIV1 = `${API}/api/v1`;
const APIV2 = `${API}/v2`;
const geocodeAPI = `https://maps.googleapis.com/maps/api/geocode/json`;

export {
    API,
    APIV1,
    APIV2,
    commonAPI,
    adminAPI,
    agencyAPI,
    developerAPI,
    imageAPI,
    geocodeAPI
};
