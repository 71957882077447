<template>
  <div
    class="fd-textarea fd-validation"
    :class="{ error: hasError, 'shake-horizontal': hasError }"
  >
    <label v-if="label" :for="name" class="label"
      >{{ label }} <span style="color: red" v-if="isRequired">*</span></label
    >
    <textarea
      :rows="rows"
      :value="value"
      :name="name"
      :placeholder="placeholder"
      :required="required"
      :readonly="readonly"
      :disabled="disabled"
      :class="[resizeClass(), { 'auto-resize': autoResize }]"
      @input="onInput"
    >
    </textarea>
    <!-- Implement validation with error message -->
    <span v-if="hasError" class="errorMsg">{{ errorMessage }}</span>
  </div>
</template>

<script>
import validator from "./Validator/mixin/validatorMixin";

export default {
  components: {},
  mixins: [validator],
  computed: {},
  props: {
    value: {
      type: String,
      default: "",
      required: true
    },
    rows: {
      type: Number,
      default: 4
    },
    resize: {
      type: String,
      default: "none"
    },
    autoResize: {
      type: Boolean,
      default: true
    },
    name: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: "text"
    },
    label: {
      type: String
    },
    placeholder: {
      type: String
    },
    required: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    error: {
      type: String
    }
  },
  data: function () {
    return {};
  },
  watch: {
    value: {
      handler(newVal, oldVal) {
        this.hasError = false;
      },
      deep: true
    }
  },
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    onInput(event) {
      if (this.autoResize) {
        this.resizeTextBox(event);
      }
      this.hasError = false;
      this.$emit("input", event.target.value);
    },
    resizeClass() {
      switch (this.resize) {
        case "none":
          return "";
        case "both":
          return "resizable";
        case "horizontal":
          return "resizable horizontal";
        case "vertical":
          return "resizable vertical";

        default:
          break;
      }
    },
    resizeTextBox(event) {
      event.target.style.height = "auto";
      event.target.style.height = `${event.target.scrollHeight}px`;
    }
  }
};
</script>

<style lang="scss">
@mixin resize($dimension) {
  resize: $dimension;
  overflow: auto;
}

.fd-textarea {
  textarea {
    width: 100%;
    display: block;
    resize: none;
    &:focus {
      transition: border 0.25s;
      border: solid 1px $color-main;
    }
    &.resizable {
      @include resize(both);

      &.horizontal {
        @include resize(horizontal);
      }
      &.vertical {
        @include resize(vertical);
      }
    }
    &.auto-resize {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
  &.error {
    $errorColor: #db4141;
    textarea {
      border: 2px solid $errorColor;
    }
    .errorMsg {
      color: $errorColor;
    }
  }
}

.label {
  margin-bottom: 4px;
  color: #5f5f5f;
  display: block;
}
</style>
