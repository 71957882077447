<template>
  <div class="fd-checkbox">
    <div
      class="d-flex align-items-center checkbox-wrapper"
      @click="
        () => {
          if (!disabled) {
            checked = !checked;
          }
        }
      "
    >
      <input
        type="checkbox"
        v-model="checked"
        :name="name"
        :placeholder="placeholder"
        :required="required"
        :readonly="readonly"
        :disabled="disabled"
      />
      <label :for="name" class="label">{{ label }}</label>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  mixins: [],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: ""
    },
    label: {
      type: String
    },
    placeholder: {
      type: String
    },
    required: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    error: {
      type: String
    }
  },
  data: function () {
    return {
      checked: false
    };
  },
  computed: {},
  watch: {
    value(val) {
      this.checked = val;
    },
    checked(val) {
      this.$emit("input", val);
    }
  },
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.checked = this.value;
  },
  methods: {}
};
</script>

<style lang="scss">
.fd-checkbox {
  .checkbox-wrapper {
    width: fit-content;
    cursor: pointer;

    input[type="checkbox"] {
      zoom: 1.4;
      display: inline-block;
      cursor: pointer;
    }
    label {
      margin-left: 5px;
      margin-bottom: 0px;
      color: #5f5f5f;
      display: inline-block;
      font-size: 14px;
      cursor: pointer;
      width: max-content;
    }
  }
}
</style>
