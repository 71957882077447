<template>
  <div
    class="fd-date fd-validation"
    :class="{ error: hasError, 'shake-horizontal': hasError }"
  >
    <label v-if="label" :for="name" class="label"
      >{{ label }} <span style="color: red" v-if="isRequired">*</span></label
    >
    <div class="position-relative d-block">
      <div
        v-if="clearable"
        class="append-icon cursor-pointer"
        @click="$emit('input', '')"
      >
        <i class="fas fa-times"></i>
      </div>
      <input
        :value="date"
        :name="name"
        type="date"
        :placeholder="placeholder"
        @input="onInput"
        :required="required"
        :readonly="readonly"
        :disabled="disabled"
        :pattern="pattern"
        :title="title"
        :min="min"
        :max="max"
      />
    </div>

    <!-- Validation with error message -->
    <span v-if="hasError" class="errorMsg">{{ errorMessage }}</span>
  </div>
</template>

<script>
import validator from "./Validator/mixin/validatorMixin";

export default {
  components: {},
  mixins: [validator],
  props: {
    pattern: {
      default: null
    },
    title: null,
    value: {
      type: [String, Number, Date],
      default: "",
      required: true
    },
    name: {
      type: String,
      default: ""
    },
    label: {
      type: String
    },
    placeholder: {
      type: String
    },
    required: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    min: {
      type: String,
      default: null
    },
    max: {
      type: String,
      default: null
    },
    clearable: {
      type: Boolean,
      default: null
    },
    error: {
      type: String
    }
  },
  data: function () {
    return {};
  },
  computed: {
    date() {
      return this.$moment(this.value).format("YYYY-MM-DD");
    }
  },
  watch: {
    date(val) {
      this.$emit("input", val);
    }
  },
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    onInput(event) {
      this.hasError = false;
      this.$emit("input", event.target.value);
    }
  }
};
</script>

<style lang="scss">
.fd-date {
  input {
    width: 100%;
    display: block;
    &:focus {
      transition: 0.25s;
      border: solid 1px $color-main;
    }
  }

  .append-icon {
    display: flex;
    align-items: center;
    position: absolute;
    z-index: 1;
    font-size: 16px;
    color: #303030;
    right: 10px;
    height: 100%;
  }

  &.error {
    $errorColor: #db4141;
    input {
      border: 2px solid $errorColor;
    }
    .errorMsg {
      color: $errorColor;
    }
  }
}

.label {
  margin-bottom: 4px;
  color: #5f5f5f;
  display: block;
}
</style>