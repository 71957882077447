<template>
  <div>
    <!-- <div v-if="!_.isEmpty(filters)" class="card flat p-2 mb-1">
      <chip
        v-for="(filter, index) in Object.entries(filters)"
        :key="index"
        class="mr-1"
        style="font-size: 14px"
      >
        {{ filter[1] }}
      </chip>
    </div> -->
    <div
      v-show="useToggle ? true : value"
      :class="[{ card: card, bordered: bordered }, 'mb-2']"
    >
      <div v-if="useToggle" class="row">
        <fd-button
          :class="['toggle m-2', { active: value }]"
          @click="$emit('input', !value)"
        >
          <i class="fas fa-filter"></i>
          <span class="d-none sm-d-inline-block ml-1">Filter</span>
        </fd-button>
      </div>
      <transition name="slide-down">
        <fd-form v-show="value" @submit="$emit('submit')">
          <div class="row mxn-2 p-2" v-show="isLoading">
            <div
              v-for="index in loadingItems"
              :key="index"
              class="col-12 sm-col-6 lg-col-4 px-2 mb-2"
            >
              <div class="skeleton w-25 mb-1"></div>
              <div class="skeleton w-100"></div>
            </div>
          </div>
          <div v-show="!isLoading" class="filter-content">
            <slot></slot>
          </div>
          <!-- Action Buttons -->
          <div class="row justify-content-end py-2 px-2">
            <div v-if="$slots.actionButtons">
              <slot name="actionButtons"></slot>
            </div>
            <div v-else>
              <fd-button class="mr-1" @click="$emit('clear')">Clear</fd-button>
              <fd-button type="submit" class="main" @click="$emit('filter')"
                >Filter</fd-button
              >
            </div>
          </div>
        </fd-form>
      </transition>
    </div>
  </div>
</template>

<script>
import "@/assets/GlobalStyling/static-css/skeleton.scss";
import "animate.css";

export default {
  components: {
    FdButton: () =>
      import("@/components/GlobalComponents/FormComponents/FdButton")
    // Chip: () => import("@/components/GlobalComponents/Chip")
  },
  mixins: [],
  props: {
    value: {
      type: Boolean
    },
    card: {
      type: Boolean,
      default: true
    },
    bordered: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    loadingItems: {
      type: Number,
      default: 1
    },
    filters: {
      type: Object,
      default: () => ({})
    },
    useToggle: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {};
  },
  computed: {},
  watch: {
    value: {
      handler(newVal) {
        this.$emit("input", newVal);
      }
    }
  },
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    cleanQueryParam(obj) {
      let cleanObj = Object.assign(obj);
      for (let val in cleanObj) {
        if (cleanObj[val] === "") {
          delete cleanObj[val];
        }
      }
      return cleanObj;
    }
  }
};
</script>

<style lang="scss">
.bordered {
  border: solid 1px #ddd;
  border-radius: 5px;
}
</style>
