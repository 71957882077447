import Vue from "vue";
import VueRouter from "vue-router";

// import Appointment from "@/views/appointment/index";
// import AppointmentAdd from "@/views/appointment/add/index";
// import AppointmentEdit from "@/views/appointment/edit/_edit";

import projectRoutes from "./projectRoutes";
import projectSalesRoutes from "./projectSalesRoutes";
import acnRoutes from "./acnRoutes";

Vue.use(VueRouter);

const auth = true;

const routes = [
    {
        path: "/forgot-password",
        name: "ForgotPassword",
        component: () => import("@/views/forgotPassword"),
        meta: {
            auth: !auth
        }
    },
    {
        path: "/login",
        name: "Login",
        component: () => import("@/views/index"),
        meta: {
            auth: !auth
        }
    },
    {
        path: "/",
        name: "Entry",
        component: () => import("@/views/entry"),
        redirect: { name: "ACN" },
        meta: {
            auth: auth
        },
        children: [
            /* =================================================================
             *                         Manage Projects
             * =================================================================
             */
            ...projectRoutes,

            /* =================================================================
             *                         Manage Project Sale
             * =================================================================
             */
            ...projectSalesRoutes,

            /* =================================================================
             *                    Agent Collaboration Network
             * =================================================================
             */
            ...acnRoutes,

            /* =================================================================
             *                              Subsales
             * =================================================================
             */
            // ============ Manage Property ============
            {
                path: "/manage-property",
                name: "ManageProperty",
                component: () => import("@/views/manageProperty/index"),
                meta: {
                    auth: auth
                }
            },
            {
                path: "/manage-property/add",
                name: "ManagePropertyAdd",
                component: () => import("@/views/manageProperty/add/index"),
                meta: {
                    auth: auth
                }
            },
            {
                path: "/manage-property/:id(\\d+)",
                name: "ManagePropertyDetail",
                component: () =>
                    import("@/views/manageProperty/detail/_detail"),
                meta: {
                    auth: auth
                }
            },
            {
                path: "/manage-property/:id(\\d+)/edit",
                name: "ManagePropertyEdit",
                component: () => import("@/views/manageProperty/edit/_edit"),
                meta: {
                    auth: auth
                }
            },
            {
                path: "/manage-property/:id(\\d+)/exclusive",
                name: "ManagePropertyExclusive",
                component: () =>
                    import("@/views/manageProperty/exclusive/_exclusive"),
                meta: {
                    auth: auth
                }
            },
            {
                path: "/manage-exclusive-submissions",
                name: "ManageExclusiveSubmissions",
                component: () => import("@/views/manageExclusiveSubmissions"),
                meta: {
                    auth: auth,
                    permisssion: "managePropertyExclusive.enabled"
                }
            },
            // ============ Booking ============
            {
                path: "/subsales/bookings",
                name: "SubsalesBookingList",
                component: () => import("@/views/subsalesBooking"),
                meta: {
                    auth: auth
                }
            },
            {
                path: "/subsales/bookings/:id",
                name: "SubsalesBookingDetail",
                component: () =>
                    import("@/views/subsalesBooking/detail/_detail"),
                meta: {
                    auth: auth
                }
            },

            /* =================================================================
             *                              Rent
             * =================================================================
             */
            {
                path: "/rent/bookings",
                name: "RentBookingList",
                component: () => import("@/views/rent"),
                meta: {
                    auth: auth
                }
            },
            {
                path: "/rent/bookings/:id",
                name: "RentBookingDetail",
                component: () => import("@/views/rent/detail/_detail"),
                meta: {
                    auth: auth
                }
            },
            {
                path: "/rent/archives",
                name: "ArchivePropertyRentList",
                component: () => import("@/views/rent/archive"),
                meta: {
                    auth: auth
                }
            },
            {
                path: "/rent/archives/:id",
                name: "ArchivePropertyRentDetail",
                component: () => import("@/views/rent/archive/detail/_detail"),
                meta: {
                    auth: auth
                }
            },
            {
                path: "/rent/archives/:id/edit",
                name: "ArchivePropertyRentEdit",
                component: () => import("@/views/rent/archive/edit/_edit"),
                meta: {
                    auth: auth
                }
            },
            /* =================================================================
             *                              Auction
             * =================================================================
             */
            {
                path: "/auction/registrations",
                name: "AuctionRegistrationList",
                component: () => import("@/views/auctionRegistration"),
                meta: {
                    auth: auth,
                    permission: "manageAuction.enable"
                }
            },
            {
                path: "/auction/registrations/:id",
                name: "AuctionRegistrationDetail",
                component: () =>
                    import("@/views/auctionRegistration/detail/_detail"),
                meta: {
                    auth: auth,
                    permission: "manageAuction.enable"
                }
            },

            /* =================================================================
             *                         User/Org Management
             * =================================================================
             */
            // ============ Agency ============
            {
                path: "/agency-profile",
                name: "AgencyProfile",
                component: () => import("@/views/agencyProfile"),
                meta: {
                    auth: auth,
                    permission: "agencyProfile.update"
                }
            },
            // ============ Sales Report ============
            {
                path: "/sales-report",
                name: "SalesReport",
                component: {
                    render(c) {
                        return c("router-view");
                    }
                },
                meta: {
                    auth: auth,
                    permission: "report.view"
                },
                children: [
                    {
                        path: "agency",
                        name: "AgencySalesReport",
                        component: () => import("@/views/agencySalesReport"),
                        meta: {
                            auth: auth,
                            permission: "report.view"
                        }
                    },
                    {
                        path: "branch/:id",
                        name: "BranchSalesReport",
                        component: () => import("@/views/branchSalesReport"),
                        meta: {
                            auth: auth,
                            permission: "report.view"
                        }
                    },
                    {
                        path: "branch/:branchId/agent/:id",
                        name: "AgentSalesReport",
                        component: () => import("@/views/agentSalesReport"),
                        meta: {
                            auth: auth,
                            permission: "report.view"
                        }
                    }
                ]
            },

            // ========= Agency Roles =========
            {
                path: "/manage-agency-role",
                name: "ManageAgencyRole",
                component: () => import("@/views/manageAgencyUserRole"),
                meta: {
                    auth: auth,
                    permission: "agencyUserRole.view"
                }
            },
            {
                path: "/manage-agency-role/add",
                name: "ManageAgencyRoleAdd",
                component: () =>
                    import("@/views/manageAgencyUserRole/add/index"),
                meta: {
                    auth: auth,
                    permission: "agencyUserRole.create"
                }
            },
            {
                path: "/manage-agency-role/edit/:id",
                name: "ManageAgencyRoleEdit",
                component: () =>
                    import("@/views/manageAgencyUserRole/edit/_edit"),
                meta: {
                    auth: auth,
                    permission: "agencyUserRole.update"
                }
            },
            // ========= Manage Agency Users =========
            {
                path: "/manage-agency-users",
                name: "ManageAgencyUsers",
                component: () => import("@/views/manageAgencyUsers/index"),
                meta: {
                    auth: auth,
                    permission: "agencyUser.view"
                }
            },
            {
                path: "/manage-agency-users/add",
                name: "ManageAgencyUsersAdd",
                component: () => import("@/views/manageAgencyUsers/add/index"),
                meta: {
                    auth: auth,
                    permission: "agencyUser.create"
                }
            },
            {
                path: "/manage-agency-users/edit/:id",
                name: "ManageAgencyUsersEdit",
                component: () => import("@/views/manageAgencyUsers/edit/_edit"),
                meta: {
                    auth: auth,
                    permission: "agencyUser.update"
                }
            },
            // ============ Manage Branch ============
            {
                path: "/manage-branch",
                name: "ManageBranch",
                component: () => import("@/views/manageBranch/index"),
                meta: {
                    auth: auth,
                    permission: "agencyBranch.view"
                }
            },
            {
                path: "/manage-branch/add",
                name: "ManageBranchAdd",
                component: () => import("@/views/manageBranch/add/index"),
                meta: {
                    auth: auth,
                    permission: "agencyBranch.create"
                }
            },
            {
                path: "/manage-branch/edit/:id",
                name: "ManageBranchEdit",
                component: () => import("@/views/manageBranch/edit/_edit"),
                meta: {
                    auth: auth,
                    permission: "agencyBranch.update"
                }
            },
            {
                path: "/manage-branch/:id/bank-accounts",
                name: "ManageBranchBankAccounts",
                component: () => import("@/views/manageBranch/bankAccount"),
                meta: {
                    auth: auth,
                    permission: "agencyBranch.update"
                }
            },
            // ============ Manage Account ============
            {
                path: "/manage-account",
                name: "ManageAccount",
                component: () => import("@/views/manageAccount/_edit"),
                meta: {
                    auth: auth
                }
            }
        ]
    },
    /* =========================================================================
     *                              Miscellaneous
     * =========================================================================
     */
    {
        path: "/maintenance",
        name: "Maintenance",
        component: () => import("@/views/maintenance"),
        meta: {
            auth: undefined
        }
    },
    {
        path: "/*",
        name: "NotFound",
        component: () => import("@/views/404/NotFound"),
        meta: {
            auth: undefined
        }
    }
];

const router = new VueRouter({
    mode: "history",
    routes
});

Vue.router = router;

import permissionMiddleware from "./middleware/permission";
import store from "@/store";

router.beforeEach((to, from, next) => {
    let isMaintenance = process.env.VUE_APP_MAINTENANCE_MODE;
    if (isMaintenance == 1) {
        if (to.name == "Maintenance") {
            next();
            return;
        }
        next({ name: "Maintenance" });
        return;
    }

    // Notification hack (Fetch once every route change)
    if (Vue.prototype.$auth.check()) {
        store.dispatch("checkHasUnreadNotification");
    }

    permissionMiddleware({ to }).then((data) => {
        if (data) {
            next();
            return;
        } else {
            next("/");
            return;
        }
    });
});

export default router;
