<template>
  <div ref="fdRouteTab" class="fd-route-tab">
    <div v-if="isActive" class="window"></div>
  </div>
</template>

<script>
export default {
  name: "fd-route-tab",
  props: {
    label: String,
    to: [String, Object],
    iconClass: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      isActive: false
    };
  },
  updated() {
    this.$parent.$emit("update");
  }
};
</script>

<style lang="scss"></style>
