function collapseDrop() {
    document.querySelectorAll(".fd-sidebar .drop").forEach((each) => {
        each.classList.remove("active");
    });
    $(".fd-sidebar .dropdown").slideUp(300);
}

export default {
    state: {
        isSidebarOpen: false
    },
    getters: {
        getSidebarState(state) {
            return state.isSidebarOpen;
        }
    },
    mutations: {
        toggleSidebar(state) {
            state.isSidebarOpen ? collapseDrop() : "";
            state.isSidebarOpen = !state.isSidebarOpen;
        },
        openSidebar(state) {
            state.isSidebarOpen = true;
        },
        closeSidebar(state) {
            collapseDrop();
            state.isSidebarOpen = false;
        }
    }
};
