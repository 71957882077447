import axios from "axios";

export default {
    namespaced: true,
    state: () => {
        return {};
    },
    getters: {},
    mutations: {},
    actions: {
        async getAllBooking(
            { commit },
            { queries = {}, page = 1, perPage = 15 }
        ) {
            try {
                let include = "";

                let response = await axios.get(
                    `${this._vm.$agencyAPI}/subsale/purchase`,
                    {
                        params: {
                            ...queries,
                            include: include,
                            page: page,
                            limit: perPage
                        }
                    }
                );

                return response.data;
            } catch (error) {
                throw error;
            }
        },
        async getBookingDetail({ commit }, { id }) {
            let include = "";

            try {
                let response = await axios.get(
                    `${this._vm.$agencyAPI}/subsale/purchase/${id}?include=${include}`
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        async signWitness({ commit }, { id, payload }) {
            try {
                const request = await axios.put(
                    `${this._vm.$agencyAPI}/subsale/purchase/${id}/sign-witness`,
                    payload
                );

                return request.data;
            } catch (error) {
                throw error;
            }
        },
        async generateVendorSignLink({ commit }, { id, payload }) {
            try {
                const request = await axios.post(
                    `${this._vm.$agencyAPI}/subsale/purchase/${id}/vendor-sign`,
                    payload
                );

                return request.data;
            } catch (error) {
                throw error;
            }
        },
        async cancelBooking({ commit }, { id, payload }) {
            try {
                const request = await axios.put(
                    `${this._vm.$agencyAPI}/subsale/purchase/${id}/cancel`
                );

                return true;
            } catch (error) {
                throw error;
            }
        },
        async markAsCancelled({ commit }, { id, payload }) {
            try {
                const request = await axios.put(
                    `${this._vm.$agencyAPI}/subsale/purchase/${id}/cancelled`
                );

                return true;
            } catch (error) {
                throw error;
            }
        },
        async markAsSuccess({ commit }, { id, payload }) {
            try {
                const request = await axios.put(
                    `${this._vm.$agencyAPI}/subsale/purchase/${id}/completed`,
                    payload
                );

                return true;
            } catch (error) {
                throw error;
            }
        }
    }
};
