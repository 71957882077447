<template>
  <transition name="modal">
    <div v-if="value" class="fd-modal">
      <div class="fd-modal__overlay" @click.self="onOverlayClicked">
        <div
          :class="{ shake: isShaking, fullscreen: fullscreen }"
          class="fd-modal__content"
          @animationend="isShaking = false"
        >
          <slot></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
/**
 * Modal for general purpose use. Provided a slot to put in any content.
 *
 * :value [Boolean] To control modal shown or not.
 * :persistent [Boolean] To control whether the modal can be closed by clicking on overlay.
 *
 * @close Event emit on modal close.
 * @input Event emit on modal state change.
 */
import router from "@/router";

export default {
  components: {},
  mixins: [],
  props: {
    value: {
      type: Boolean,
      default: false,
      required: true
    },
    persistent: {
      type: Boolean,
      default: false
    },
    fullscreen: {
      type: Boolean,
      default: false
    },
    isNested: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      isShaking: false
    };
  },
  computed: {},
  watch: {
    value: {
      handler(newVal) {
        this.$emit("input", newVal);

        if (newVal) {
          this.disableBackdropScroll();
        } else {
          if (!this.isNested) {
            this.enableBackdropScroll();
          }
        }
      }
    }
  },
  created: function () {},
  beforeDestroy: function () {
    this.enableBackdropScroll();
  },
  mounted: function () {
    router.beforeEach((to, from, next) => {
      this.enableBackdropScroll();
      next();
    });
  },
  methods: {
    disableBackdropScroll() {
      document.body.setAttribute("style", "overflow: hidden;");
    },
    enableBackdropScroll() {
      document.body.removeAttribute("style");
    },
    onOverlayClicked() {
      if (this.persistent) {
        this.isShaking = true;
        return;
      }
      this.$emit("input", false);
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss">
.fd-modal {
  .fd-modal__overlay {
    position: fixed;
    z-index: 102;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    transition: opacity 0.3s ease;
    display: flex;

    .fd-modal__content {
      border-radius: 8px;
      overflow-y: auto;
      max-width: 90vw;
      width: fit-content;
      max-height: 90vh;
      margin: auto;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
      transition: all 0.3s ease;

      &.fullscreen {
        width: 100vw;
        height: 100vh;
        max-width: 100vw;
        max-height: 100vh;
        border-radius: 0;
        margin: auto;
        overflow-y: auto;
        box-shadow: none;

        .fd-modal__content-wrapper,
        .modal-content-wrapper {
          width: 100vw;
          height: 100vh;
          max-width: 100vw;
          max-height: 100vh;
        }
      }

      &.shake {
        animation: shake 0.2s;
      }
      @keyframes shake {
        0% {
          transform: translateX(2px);
        }
        50% {
          transform: translateX(-2px);
        }
        100% {
          transform: translate(0px, 0px);
        }
      }

      .fd-modal__content-wrapper,
      .modal-content-wrapper {
        display: flex;
        flex-flow: column;

        max-width: 90vw;
        width: fit-content;
        max-height: 90vh;
      }
    }
  }
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .fd-modal__content,
.modal-leave-active .fd-modal__content {
  transform: translateY(-15px);
}
</style>
