<template>
  <button
    :type="type"
    class="fd-button"
    :class="{ compact: compact, medium: medium }"
    @click="
      (e) => {
        if (this.loadingEnabled && this.isLoading) {
        } else {
          this.$emit('click', e);
        }
      }
    "
    :disabled="disabled"
  >
    <div class="d-block">
      <spinner
        v-if="loadingEnabled && isLoading"
        class="d-inline-block mr-1"
        :size="spinnerSize"
        :color="spinnerColor"
      ></spinner>
      <i v-if="icon && !isLoading" :class="`${icon} mr-1`"></i>
      <slot></slot>
    </div>
  </button>
</template>

<script>
export default {
  components: {
    Spinner: () =>
      import("@/components/GlobalComponents/LoaderComponent/Spinner")
  },
  mixins: [],
  props: {
    type: {
      type: String,
      default: "button"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ""
    },
    icon: {
      type: String
    },
    compact: {
      type: Boolean,
      default: true
    },
    medium: {
      type: Boolean,
      default: false
    },

    // Spinner
    loadingEnabled: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    spinnerSize: {
      type: String,
      default: "16px"
    },
    spinnerColor: {
      type: String,
      default: "white"
    }
  },
  data: function () {
    return {};
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss">
.fd-button {
  @mixin btnBordered($color) {
    $text-color: white;
    @if $color == white {
      $text-color: #333;
    }
    background-color: transparent;
    border: solid 1px $color;
    color: $color;
    &:hover {
      background-color: $color;
      color: $text-color;
      i {
        color: $text-color;
      }
    }
    i {
      color: $color;
    }
  }

  @mixin btnFlat($color) {
    background: transparent;
    color: $color;
    i {
      color: $color;
    }
    &:hover,
    &:focus {
      background-color: $color;
      color: setTextColor($color);
      i {
        color: setTextColor($color);
      }
    }
  }

  @mixin btnToggle($color) {
    background: none;
    color: #909090;
    &:hover {
      background: #e6e6e6;
    }
    &.active {
      background: $color;
      color: white;
      box-shadow: 0px 4px 10px #00000025;
      i {
        color: white;
      }
      &:hover {
        box-shadow: none;
      }
    }
  }

  @mixin btnClass($class, $color: #f1f1f1) {
    &.bordered.#{$class} {
      @include btnBordered($color);
    }
    &.flat.#{$class} {
      @include btnFlat($color);
    }
    &.toggle.#{$class} {
      @include btnToggle($color);
    }
    &.#{$class} {
      background-color: $color;
      color: white;
      // Disabled color
      &:disabled {
        background-color: #ccc;
        cursor: not-allowed;
        &:hover {
          background-color: #ccc;
        }
      }
      &:hover,
      &:focus {
        color: white;
        background-color: darken($color, 5);
        i {
          color: white;
        }
      }
      i {
        color: white;
      }
    }
  }

  @function setTextColor($color, $black: #333, $white: #fff) {
    @if (lightness($color) > 60) {
      @return $black; // Lighter color, return black
    } @else {
      @return $white; // Darker color, return white
    }
  }

  border: none;
  background-color: #f1f1f1;
  color: #808080;
  font-size: 14px;
  line-height: 1.2;
  transition: 0.3s;
  padding: 8px 16px;
  border-radius: 4px;
  display: inline-block;
  min-width: 84px;
  &.compact {
    min-width: initial;
  }

  &:hover,
  &:focus {
    background-color: darken(#f1f1f1, 5);
  }

  &.medium {
    font-size: 15px;
    padding: 12px 24px;
  }

  &.large {
    font-size: 16px;
    padding: 16px 32px;
  }

  &.fixed {
    position: fixed;
    bottom: 20px;
    right: 20px;
    box-shadow: 0px 2px 15px #00000050;
    z-index: 2;
  }
  &.round {
    border-radius: 50px;
  }
  &.circle {
    min-width: initial;
    border-radius: 9999em;
    width: 36px;
    height: 36px;
    font-size: 12px;
    padding: 10px;
    &.xs {
      font-size: 12px;
      width: 24px;
      height: 24px;
    }
    &.sm {
      width: 32px;
      height: 32px;
      font-size: 12px;
    }
    &.lg {
      width: 50px;
      height: 50px;
      font-size: 18px;
    }
    span {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &.bordered {
    @include btnBordered(#aaa);
  }
  &.flat {
    @include btnFlat(#a8a8a8);
  }
  &.toggle {
    @include btnToggle($color-main);
  }

  @include btnClass(main, $color-main);
  @include btnClass(secondary, $color-secondary);
  @include btnClass(accent, $color-accent);
  @include btnClass(accent2, $color-accent2);
  @include btnClass(success, $color-success);
  @include btnClass(danger, $color-danger);
  @include btnClass(warn, $color-warn);
  @include btnClass(white, white);

  &.xs {
    padding: 4px 8px;
    font-size: 12px;
    margin: 0 2px;
    line-height: 1.2;
    letter-spacing: 0.5px;
  }
  &.sm {
    padding: 8px 12px;
    font-size: 14px;
  }
  &.lg {
    padding: 12px 16px;
    font-size: 18px;
  }
}
</style>
