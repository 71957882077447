import axios from "axios";

export default {
    namespaced: true,
    facility: () => {
        return {};
    },
    getters: {},
    mutations: {},
    actions: {
        async getAllVideos(
            { commit },
            { queries = {}, page = 1, perPage = 15 }
        ) {
            try {
                let include = "agencyUser";

                let response = await axios.get(
                    `${this._vm.$agencyAPI}/floor-plan/videos` +
                        `?page=${page}&limit=${perPage}&include=${include}`,
                    { params: queries }
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        async getVideo({ commit }, id) {
            try {
                let include = "";

                let response = await axios.get(
                    `${this._vm.$agencyAPI}/floor-plan/videos/${id}` +
                        `?include=${include}`
                );

                return response.data;
            } catch (error) {
                throw error;
            }
        },
        async createVideo({ commit }, payload) {
            try {
                const request = await axios.post(
                    `${this._vm.$agencyAPI}/floor-plan/videos`,
                    payload
                );

                return request.data;
            } catch (error) {
                throw error;
            }
        },
        async updateVideo({ commit }, { id, payload }) {
            try {
                const request = await axios.put(
                    `${this._vm.$agencyAPI}/floor-plan/videos/${id}`,
                    payload
                );

                return request.data;
            } catch (error) {
                throw error;
            }
        },
        async approveVideo({ commit }, id) {
            try {
                const response = await axios.put(
                    `${this._vm.$agencyAPI}/floor-plan/videos/${id}/status`,
                    {
                        status: "Approval"
                    }
                );

                return response.data;
            } catch (error) {
                throw error;
            }
        },
        async rejectVideo({ commit }, id) {
            try {
                const response = await axios.put(
                    `${this._vm.$agencyAPI}/floor-plan/videos/${id}/status`,
                    {
                        status: "Reject"
                    }
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        async deleteVideo({ commit }, id) {
            try {
                const request = await axios.delete(
                    `${this._vm.$agencyAPI}/floor-plan/videos/${id}`
                );

                return request;
            } catch (error) {
                throw error;
            }
        }
    }
};
