import axios from "axios";

export default {
    namespaced: true,
    state: () => {
        return {};
    },
    getters: {},
    mutations: {},
    actions: {
        async getAllPrivateRequests(
            { commit },
            { queries = {}, page = 1, perPage = 15, self = false }
        ) {
            try {
                let include = "";
                self
                    ? (include = "property.agencyUser")
                    : (include = "requestFrom");

                let response = await axios.get(
                    `${this._vm.$agencyAPI}/request-to-partnership`,
                    {
                        params: {
                            ...queries,
                            include: include,
                            page: page,
                            limit: perPage
                        }
                    }
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        async getPrivateRequest({ commit }, { id }) {
            let include = "requestFrom";

            try {
                let response = await axios.get(
                    `${this._vm.$agencyAPI}/request-to-partnership/${id}?include=${include}`
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        async acceptRequest({ commit }, { id, payload }) {
            try {
                const response = await axios.post(
                    `${this._vm.$agencyAPI}/request-to-partnership/${id}/accept`,
                    payload
                );

                return response.data;
            } catch (error) {
                throw error;
            }
        },
        async rejectRequest({ commit }, { id, payload }) {
            try {
                const response = await axios.post(
                    `${this._vm.$agencyAPI}/request-to-partnership/${id}/reject`,
                    payload
                );

                return response.data;
            } catch (error) {
                throw error;
            }
        },
        async cancelRequest({ commit }, { id, payload }) {
            try {
                const response = await axios.post(
                    `${this._vm.$agencyAPI}/request-to-partnership/${id}/cancel`,
                    payload
                );

                return response.data;
            } catch (error) {
                throw error;
            }
        }
    }
};
