<template>
  <div class="loader">
    <div class="loader-wrapper">
      <spinner></spinner>
    </div>
  </div>
</template>

<script>
import Spinner from "./Spinner";
export default {
  components: {
    Spinner
  },
  mixins: [],
  props: {},
  data: function () {
    return {};
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss" scoped>
$loaderWrapperColor: rgba(245, 245, 245, 0.6);

.loader-wrapper {
  background: $loaderWrapperColor;
  position: fixed;
  z-index: 103;
  height: 100vh;
  width: 100%;
  bottom: 0;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
