export default [
    {
        path: "/projects",
        name: "ManageProjects",
        component: () => import("@/views/manageProjects/index"),
        meta: {
            auth: true,
            permission: "project.view"
        }
    },
    {
        path: "/projects/add",
        name: "ManageProjectsAdd",
        component: () => import("@/views/manageProjects/add/index"),
        meta: {
            auth: true,
            permission: "project.create"
        }
    },
    {
        path: "/projects/edit/:id",
        name: "ManageProjectsEdit",
        component: () => import("@/views/manageProjects/edit/_edit"),
        meta: {
            auth: true,
            permission: "project.update"
        }
    },
    {
        path: "/projects/details/:id",
        name: "ManageProjectsDetails",
        component: () => import("@/views/manageProjects/detail/_detail"),
        meta: {
            auth: true,
            permission: "project.view"
        }
    },
    {
        path: "/projects/:projectId/unit-type/:id",
        name: "ManageProjectUnitTypesDetails",
        component: () =>
            import("@/views/manageProjectUnitTypes/detail/_detail"),
        meta: {
            auth: true,
            permission: "project.view"
        }
    },
    {
        path: "/projects/:projectId/unit-type/:unitTypeId/floor-plan/:id",
        name: "ManageProjectFloorPlanDetails",
        component: () =>
            import("@/views/manageProjectFloorPlans/detail/_detail"),
        meta: {
            auth: true,
            permission: "project.view"
        }
    },
    {
        path: "/projects/:id/allocate-agents",
        name: "ProjectAllocateAgents",
        component: () => import("@/views/manageProjects/allocateAgents"),
        meta: {
            auth: true,
            permission: "projectPurchase.manage"
        }
    },
    {
        path: "/projects/:id/allocate-agents/add",
        name: "ProjectAllocateAgentsAdd",
        component: () => import("@/views/manageProjects/allocateAgents/add"),
        meta: {
            auth: true,
            permission: "projectPurchase.manage"
        }
    },
    // Allocate Bankers
    {
        path: "/projects/:id/allocate-bankers",
        name: "ProjectAllocateBankers",
        component: () => import("@/views/manageProjects/allocateBankers"),
        meta: {
            auth: true,
            permission: "projectPurchase.manage"
        }
    },
    // Allocate Lawyers
    {
        path: "/projects/:id/allocate-lawyers",
        name: "ProjectAllocateLawyers",
        component: () => import("@/views/manageProjects/allocateLawyers"),
        meta: {
            auth: true,
            permission: "projectPurchase.manage"
        }
    },

    {
        path: "/projects/assigned-units",
        name: "ManageAssignedProjectUnits",
        component: () => import("@/views/manageAssignedProjectUnits"),
        meta: {
            auth: true,
            permission: "project.view"
        }
    },
    // ============ Comments & Video ============
    {
        path: "/projects/comments",
        name: "ManageFloorPlanComments",
        component: () => import("@/views/manageFloorPlanComments"),
        meta: {
            auth: true,
            permission: "projectUnitFloorPlanComment.changeStatus"
        }
    },
    {
        path: "/projects/videos",
        name: "ManageFloorPlanVideos",
        component: () => import("@/views/manageFloorPlanVideos"),
        meta: {
            auth: true,
            permission: "projectUnitFloorPlanVideo.changeStatus"
        }
    },
    {
        path: "/projects/my-comments",
        name: "MyFloorPlanComments",
        component: () => import("@/views/manageFloorPlanComments/myComments"),
        meta: {
            auth: true,
            permission: "project.view"
        }
    },
    {
        path: "/projects/my-videos",
        name: "MyFloorPlanVideos",
        component: () => import("@/views/manageFloorPlanVideos/myVideos"),
        meta: {
            auth: true,
            permission: "project.view"
        }
    }
];
