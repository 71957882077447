<template>
  <div
    class="fd-tags-input fd-validation"
    :class="{ error: hasError, 'shake-horizontal': hasError }"
  >
    <label for="name" class="label"
      >{{ label }}
      <span style="color: red" v-if="isRequired && label != null">*</span>
    </label>

    <vue-tags-input
      v-model="tag"
      :tags="value"
      :add-on-blur="addOnBlur"
      :add-only-from-autocomplete="addOnlyFromAutocomplete"
      :allow-edit-tags="allowEditTags"
      :autocomplete-items="filteredItems"
      :avoid-adding-duplicates="avoidAddingDuplicates"
      :is-duplicate="isDuplicate"
      :disabled="disabled"
      :max-tags="maxTags"
      :placeholder="placeholder"
      :separators="separators"
      :validation="tagValidation"
      @tags-changed="onChange"
    >
    </vue-tags-input>

    <!-- Validation error message -->
    <span v-if="hasError" class="errorMsg">{{ errorMessage }}</span>
  </div>
</template>

<script>
/**
 * Refer to original author for more modification:
 * http://www.vue-tags-input.com/
 */

import VueTagsInput from "@johmun/vue-tags-input";
import validator from "./Validator/mixin/validatorMixin";

export default {
  components: { VueTagsInput },
  mixins: [validator],
  props: {
    label: {
      type: String
    },
    name: {
      type: String
    },
    value: {
      default: []
    },
    placeholder: {
      type: String,
      default: ""
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    addOnBlur: {
      type: Boolean,
      default: false
    },
    addOnlyFromAutocomplete: {
      type: Boolean,
      default: false
    },
    allowEditTags: {
      type: Boolean,
      default: false
    },
    autocompleteItems: {
      type: Array,
      default: () => []
    },
    avoidAddingDuplicates: {
      type: Boolean,
      default: true
    },
    maxTags: {
      type: Number,
      default: undefined
    },
    separators: {
      type: Array,
      default: () => [";", ","]
    },
    tagValidation: {
      type: Array,
      default: () => []
    },
    isDuplicate: {
      type: Function
    },
    transformText: {
      type: Function,
      default: (text) => text
    }
  },
  data: function () {
    return {
      tag: ""
    };
  },
  computed: {
    filteredItems() {
      return this.autocompleteItems.filter((i) => {
        return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
      });
    }
  },
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    onChange(tags) {
      let vm = this;
      let valToEmit = tags.map((tag) => ({
        ...tag,
        text: vm.transformText(tag.text)
      }));

      this.$emit("input", valToEmit);
      this.$emit("change", tags);
    }
  }
};
</script>

<style lang="scss">
.fd-tags-input {
  .label {
    margin-bottom: 4px;
    color: #5f5f5f;
    display: block;
  }

  &.error {
    $errorColor: #db4141;
    .vue-tags-input .ti-input {
      border: 2px solid $errorColor;
    }
    .errorMsg {
      color: $errorColor;
    }
  }

  // ====================== vue-tag-input CSS Override ======================

  /* style the background and the text color of the input ... */
  .vue-tags-input {
    background: initial;
    max-width: 100%;
  }

  .vue-tags-input .ti-new-tag-input {
    background: transparent;
  }

  .vue-tags-input .ti-input {
    border-radius: 4px;
  }

  /* we cange the border color if the user focuses the input */
  .vue-tags-input.ti-focus .ti-input {
    border: 1px solid $color-main;
  }

  /* some stylings for the autocomplete layer */
  .vue-tags-input .ti-autocomplete {
    background: white;
    border: 1px solid #ccc;
    border-top: none;
    color: #454545;
    font-weight: normal;
  }

  .vue-tags-input .ti-item {
    padding: 2px 6px;
  }
  /* the selected item in the autocomplete layer, should be highlighted */
  .vue-tags-input .ti-item.ti-selected-item {
    background: $color-main;
    color: white;
  }

  /* style the placeholders color across all browser */
  .vue-tags-input {
    &::-webkit-input-placeholder,
    &::-moz-placeholder,
    &::-ms-input-placeholder,
    &::-moz-placeholder {
      color: #ccc;
    }
  }
  /* default styles for all the tags */
  .vue-tags-input .ti-tag {
    position: relative;
    background: $color-main;
    color: white;
    padding: 6px 8px;
    border-radius: 4px;
  }

  /* we defined a custom css class in the data model, now we are using it to style the tag */
  // .vue-tags-input .ti-tag.custom-class {
  //   background: transparent;
  //   border: 1px solid #ebde6e;
  //   color: #ebde6e;
  //   margin-right: 4px;
  //   border-radius: 0px;
  //   font-size: 13px;
  // }

  /* the styles if a tag is invalid */
  .vue-tags-input .ti-tag.ti-invalid {
    background-color: $color-danger;
  }

  /* if the user input is invalid, the input color should be red */
  .vue-tags-input .ti-new-tag-input.ti-invalid {
    color: $color-danger;
  }

  /* if a tag or the user input is a duplicate, it should be crossed out */
  .vue-tags-input .ti-duplicate span,
  .vue-tags-input .ti-new-tag-input.ti-duplicate {
    text-decoration: line-through;
  }
}
</style>
