/**
 * Check if string is empty.
 */
export function isStringEmpty(string) {
    return string == "" || string == null ? true : false;
}

/**
 * Check if string is empty html.
 */
export function isHTMLStringEmpty(string) {
    let innerText = new DOMParser().parseFromString(string, "text/html")
        .documentElement.textContent;
    return isStringEmpty(innerText);
}

/**
 * Converts number to string representation with K, M, B, etc.
 */
export function numberFormat(num, { decimal } = { decimal: 2 }) {
    num = Number(num);
    let thousand = 1000;
    let million = 1000000;
    let billion = 1000000000;

    if (num > 999 && num < million) {
        return (num / thousand).toFixed(decimal) + "K";
    } else if (num >= million && num < billion) {
        return (num / million).toFixed(decimal) + "M";
    } else if (num >= billion) {
        return (num / billion).toFixed(decimal) + "B";
    } else {
        return num;
    }
}
/**
 * Separate number with comma. eg: 1,000,000
 */
export function numberWithCommas(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

/**
 * Returns word format of Malaysia currency
 * (eg: Ringgit Malaysia Two Thousands Six Hundred and Fifty Cents Only)
 *
 * Uses library: https://github.com/mastermunj/to-words
 */
import { ToWords } from "to-words";
export function priceToWord(number) {
    const toWords = new ToWords();

    number = number.toFixed(2).toString();
    let ringgit = number.split(".")[0];
    let cents = Number(number.split(".")[1]);

    let ringgitWord = toWords.convert(ringgit);
    let centWord = cents == 0 ? "" : `and ${toWords.convert(cents)} Cents`;

    return `Ringgit Malaysia ${ringgitWord} ${centWord} Only`;
}

/**
 * Capitalize the first letter.
 */
export function toTitleCase(str) {
    let getSpaced = (str) => {
        if (!str) {
            return "";
        } else if (str.indexOf("_") != -1) {
            return str.split("_").join(" ");
        } else {
            return str;
        }
    };
    let titleCase = getSpaced(str).replace(
        /\w\S*/g,
        (word) => word.charAt(0).toUpperCase() + word.substr(1).toLowerCase()
    );

    return titleCase;
}

export function getFullAddress(videoLink) {
    if (!videoLink.match("https://") && videoLink.trim() !== "") {
        return `https://${videoLink}`;
    }

    return videoLink;
}

/**
 *
 * @param {String} str
 * @param {Object} options
 * @returns {String}
 */
export function elipsisMiddle(
    str,
    { startLength: startLength, endLength: endLength, trimAt: trimAt } = {
        startLength: 10,
        endLength: 10,
        trimAt: 20
    }
) {
    if (str.length > trimAt) {
        return (
            str.substr(0, startLength) +
            "..." +
            str.substr(str.length - endLength, str.length)
        );
    }
    return str;
}

/**
 *
 * @param {String} filename
 * @returns {String}
 */
export function getFileExtension(filename) {
    const extension =
        filename.substring(filename.lastIndexOf(".") + 1, filename.length) ||
        filename;
    return extension;
}
/**
 *
 * @param {String} dialCode
 * @param {String} number
 * @returns {String}
 */
export function getUserPhoneNumber({ mobileCountryCode, mobileNumber }) {
    const phoneNo =
        mobileCountryCode && mobileNumber
            ? `${mobileCountryCode}${mobileNumber}`
            : "";
    return phoneNo;
}

/**
 * Copy to clipboard
 *
 * @param {HTMLElement} element
 * @returns {String}
 */
export async function copyToClipboard(element) {
    try {
        /* Select the text field */
        element.select();
        element.setSelectionRange(0, 99999); /* For mobile devices */

        /* Copy the text inside the text field */
        navigator.clipboard.writeText(element.value);
    } catch (error) {
        throw "Failed to copy";
    }
}
