<template>
  <div
    class="fd-date-picker fd-validation"
    :class="{ error: hasError, 'shake-horizontal': hasError }"
  >
    <label v-if="label" :for="name" class="label"
      >{{ label }} <span style="color: red" v-if="isRequired">*</span></label
    >
    <div class="d-block">
      <date-picker
        :value="value"
        :model-config="modelConfig"
        :masks="displayMask"
        :is-required="required"
        :popover="{
          visibility: readonly ? 'hidden' : 'click',
          positionFixed: 'fixed'
        }"
        :disabled-dates="disabledDates"
        :min-date="min ? new Date(min) : undefined"
        :max-date="max ? new Date(max) : undefined"
        @input="onInput"
      >
        <template v-slot="{ inputValue, inputEvents }">
          <div class="position-relative">
            <input
              :class="{ 'has-end-icon': clearable }"
              :value="inputValue"
              :name="name"
              :placeholder="displayMask.input"
              :required="required"
              :readonly="readonly"
              :disabled="disabled"
              v-on="inputEvents"
              v-mask="inputMask"
            />
            <span
              v-if="clearable"
              class="append-icon cursor-pointer"
              @click="$emit('input', '')"
            >
              <i class="fas fa-times"></i>
            </span>
          </div>
        </template>
      </date-picker>
    </div>

    <!-- Validation with error message -->
    <span v-if="hasError" class="errorMsg">{{ errorMessage }}</span>
  </div>
</template>

<script>
import validator from "./Validator/mixin/validatorMixin";
import { mask } from "vue-the-mask";
import DatePicker from "v-calendar/lib/components/date-picker.umd";

export default {
  components: { DatePicker },
  directives: { mask },
  mixins: [validator],
  props: {
    "model-config": {
      type: String
    },
    title: null,
    value: {
      type: [String, Number, Date],
      default: "",
      required: true
    },
    name: {
      type: String,
      default: ""
    },
    label: {
      type: String
    },
    placeholder: {
      type: String
    },
    required: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    min: {
      type: String,
      default: undefined
    },
    max: {
      type: String,
      default: undefined
    },
    disabledDates: {
      type: Array,
      default: () => []
    },
    clearable: {
      type: Boolean,
      default: false
    },
    error: {
      type: String
    },
    modelConfig: {
      type: Object,
      default: () => ({
        type: "string",
        mask: "YYYY-MM-DD"
      })
    },
    displayMask: {
      type: Object,
      default: () => ({
        input: "DD/MM/YYYY"
      })
    },
    inputMask: {
      type: String,
      default: "##/##/####"
    }
  },
  data: function () {
    return {};
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    onInput(val) {
      this.hasError = false;
      this.$emit("input", val);
    }
  }
};
</script>

<style lang="scss">
.fd-date-picker {
  input {
    width: 100%;
    display: block;
    &:focus {
      transition: 0.25s;
      border: solid 1px $color-main;
    }
    &.has-end-icon {
      padding-right: 1.8em;
    }
  }

  span.append-icon {
    display: flex;
    align-items: center;
    position: absolute;
    z-index: 1;
    font-size: 1.2em;
    color: #303030;
    right: 10px;
    top: 0;
    height: 100%;
    background: -internal-light-dark(rgb(255, 255, 255), rgb(59, 59, 59));
  }

  &.error {
    $errorColor: #db4141;
    input {
      border: 2px solid $errorColor;
    }
    .errorMsg {
      color: $errorColor;
    }
  }
}

.label {
  margin-bottom: 4px;
  color: #5f5f5f;
  display: block;
}
</style>