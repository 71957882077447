<template>
  <form ref="fdForm" class="fd-form" @submit.prevent="submit" @change="change">
    <slot></slot>
  </form>
</template>

<script>
class FdFormError {
  label;
  name;
  message;

  constructor(label, name, message) {
    this.label = label;
    this.name = name;
    this.message = message;
  }

  getError() {
    return {
      label: this.label,
      name: this.name,
      message: this.message
    };
  }
}

export default {
  name: "fd-form",
  components: {},
  mixins: [],
  props: {
    onSubmit: Function
  },
  data: function () {
    return {
      isDirty: false,
      err: []
    };
  },
  computed: {},
  watch: {},
  created() {
    window.addEventListener("beforeunload", this.dirtyCheck);
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.dirtyCheck);
  },
  methods: {
    getValidationComponents() {
      return Array.prototype.map
        .call(
          this.$refs.fdForm.getElementsByClassName("fd-validation"),
          (field) => field.__vue__
        )
        .filter((c) => c.validate);
    },
    checkValidation() {
      if (!this.$refs.fdForm.reportValidity()) {
        return false;
      }
      let isFormValid = true;
      let components = this.getValidationComponents();
      for (let i = 0; i < components.length; i++) {
        const comp = components[i];
        const valid = comp.validate();

        if (valid == false) {
          this.createError(comp);
          this.scrollToErrorComponent();
          isFormValid = false;
        }
      }

      return isFormValid;
    },
    createError(comp) {
      let fdFormErr = new FdFormError(comp.label, comp.name, comp.errorMessage);
      this.err.push(fdFormErr.getError());
    },
    scrollToErrorComponent() {
      this.$nextTick(() => {
        let errorComponent = this.$el.querySelector(".fd-validation.error");
        errorComponent.scrollIntoView({ block: "center", inline: "center" });
      });
    },
    dirtyCheck(e) {
      if (this.isDirty) {
        var confirmationMessage =
          "You have unsaved changes. If you leave before saving, your changes will be lost.";

        (e || window.event).returnValue = confirmationMessage; //Gecko + IE
        return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
      }

      return undefined;
    },
    submit(e) {
      if (this.checkValidation()) {
        this.$emit("submit", e);
      } else {
        this.$emit("error", this.err);
      }
    },
    change(e) {
      this.isDirty = true;
      this.$emit("change", e);
    }
  }
};
</script>

<style lang="scss"></style>
