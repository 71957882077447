/// This function will automatically convert empty value to null
/// Will return original value if not empty value
export function convertNullIfEmpty(val) {
    if (val != null) {
        if (typeof val == "object") {
            if (Object.entries(val).length <= 0) {
                return null;
            }

            return val;
        }

        // Check float, and convert to string
        if (val.toString().match(/\d+\.?\d*/g)) {
            if (parseFloat(val) == 0) {
                return null;
            }

            return val.toString();
        }

        if (typeof val == "number") {
            if (val == 0) {
                return null;
            }

            return val;
        }

        if (val.toString().replace(/\s/, "") == "") {
            return null;
        }
    }

    return val;
}
/// This function will return true if the value is empty
/// Will return false if not empty value
export function isEmpty(val) {
    if (val != null) {
        if (typeof val == "object") {
            if (Object.entries(val).length <= 0) {
                return true;
            }

            return false;
        }

        // Check float, and convert to string
        if (val.toString().match(/\d+\.?\d*/g)) {
            if (parseFloat(val) == 0) {
                return true;
            }

            return false;
        }

        if (typeof val == "number") {
            if (val == 0) {
                return true;
            }

            return false;
        }

        if (val.toString().replace(/\s/, "") == "") {
            return true;
        }

        return false;
    }

    return true;
}