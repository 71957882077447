import axios from "axios";

export default {
    namespaced: true,
    state: () => {
        return {};
    },
    getters: {},
    mutations: {},
    actions: {
        // =========================================================================
        //                              Public Property
        // =========================================================================
        async getAllProperties(
            { commit },
            { queries = {}, page = 1, perPage = 15 }
        ) {
            let finalQueries = {};

            for (let x of Object.entries(queries)) {
                if (x[1] != null && x[1] != "") {
                    finalQueries[x[0]] = x[1];
                }
            }

            try {
                let include = "";

                let response = await axios.get(
                    `${this._vm.$commonAPI}/properties` +
                        `?page=${page}&limit=${perPage}&include=${include}`,
                    { params: finalQueries }
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        // =========================================================================
        //                              Agency Property
        // =========================================================================
        async getAgencyProperties(
            { commit },
            { queries = {}, page = 1, perPage = 15 }
        ) {
            try {
                let include = "";

                let response = await axios.get(
                    `${this._vm.$agencyAPI}/properties` +
                        `?page=${page}&limit=${perPage}&include=${include}`,
                    { params: queries }
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        async getAgencyProperty({ commit }, { id }) {
            let include =
                "propertyFacilities,propertyAmenities,agencyUser,offerToPurchase,offerToRent,propertyAuction";

            try {
                const response = await axios.get(
                    `${this._vm.$agencyAPI}/properties/${id}?include=${include}`
                );

                return response.data;
            } catch (error) {
                throw error;
            }
        },
        async updateProperty({ commit }, { id, payload }) {
            try {
                const request = await axios.put(
                    `${this._vm.$agencyAPI}/properties/${id}`,
                    payload
                );

                return request.data;
            } catch (error) {
                throw error;
            }
        },

        async updateStatus({ commit }, { id, status }) {
            try {
                let response = await axios.put(
                    `${this._vm.$agencyAPI}/properties/${id}/status`,
                    { status: status }
                );
                return response;
            } catch (error) {
                throw error;
            }
        },
        async updatePrice({ commit }, { id, payload }) {
            try {
                let response = await axios.put(
                    `${this._vm.$agencyAPI}/properties/${id}/price`,
                    payload
                );
                return response;
            } catch (error) {
                throw error;
            }
        },

        // =========================================================================
        //                              My Property
        // =========================================================================
        async getMyProperties(
            { commit },
            { queries = {}, page = 1, perPage = 15 }
        ) {
            try {
                let include = "belongToOwner";

                let response = await axios.get(
                    `${this._vm.$agencyAPI}/my/properties` +
                        `?page=${page}&limit=${perPage}&include=${include}`,
                    { params: queries }
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        async getMyProperty({ commit }, { id }) {
            let include =
                "propertyFacilities,propertyAmenities,agencyUser,offerToPurchase,offerToRent,propertyAuction,acnGroup,belongToOwner";

            try {
                const response = await axios.get(
                    `${this._vm.$agencyAPI}/my/properties/${id}?include=${include}`
                );

                return response.data;
            } catch (error) {
                throw error;
            }
        },
        async getMyPropertyPartnershipList({ commit }, { id }) {
            let include = "partnershipFrom,partnershipWith,property";

            try {
                const response = await axios.get(
                    `${this._vm.$agencyAPI}/my/partnerships/property/${id}?include=${include}`
                );

                return response.data;
            } catch (error) {
                throw error;
            }
        },
        async createMyProperty({ commit }, { payload }) {
            try {
                const request = await axios.post(
                    `${this._vm.$agencyAPI}/my/properties`,
                    payload
                );

                return request.data;
            } catch (error) {
                throw error;
            }
        },
        async updateMyProperty({ commit }, { id, payload }) {
            try {
                const request = await axios.put(
                    `${this._vm.$agencyAPI}/my/properties/${id}`,
                    payload
                );

                return request.data;
            } catch (error) {
                throw error;
            }
        },
        async updateMyStatus({ commit }, { id, status }) {
            try {
                let response = await axios.put(
                    `${this._vm.$agencyAPI}/my/properties/${id}/status`,
                    { status: status }
                );
                return response;
            } catch (error) {
                throw error;
            }
        },
        async updateMyPrice({ commit }, { id, payload }) {
            try {
                let response = await axios.put(
                    `${this._vm.$agencyAPI}/my/properties/${id}/price`,
                    payload
                );
                return response;
            } catch (error) {
                throw error;
            }
        },
        async repostProperty({ commit }, id) {
            try {
                let response = await axios.patch(
                    `${this._vm.$agencyAPI}/my/properties/${id}/refresh-update`
                );
                return response;
            } catch (error) {
                throw error;
            }
        },
        async activatePropertyACN({ commit }, { id, payload }) {
            try {
                let response = await axios.post(
                    `${this._vm.$agencyAPI}/my/properties/${id}/activate-acn`,
                    payload
                );
                return response;
            } catch (error) {
                throw error;
            }
        },
        async linkOwner({ commit }, { id, payload }) {
            try {
                let response = await axios.patch(
                    `${this._vm.$agencyAPI}/my/properties/${id}/link-owner`,
                    payload
                );
                return response;
            } catch (error) {
                throw error;
            }
        },
        async deleteMyProperty({ commit }, { id }) {
            try {
                let response = await axios.delete(
                    `${this._vm.$agencyAPI}/my/properties/${id}`
                );
                return response;
            } catch (error) {
                return false;
            }
        },
        async getOwnerPropertyCode({ commit }, { propertyId = "", code = "" }) {
            let include = "appUser";
            try {
                let response = await axios.get(
                    `${this._vm.$agencyAPI}/my/properties/owner-property-code`,
                    {
                        params: {
                            include: include,
                            propertyId: propertyId ?? "",
                            code: code
                        }
                    }
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },

        // =========================================================================
        //                              Miscellaneous
        // =========================================================================
        async getPropertyTypes({ commit }, { queries = {} }) {
            try {
                let response = await axios.get(
                    `${this._vm.$commonAPI}/property-types?limit=100`,
                    { params: queries }
                );
                return response.data.data;
            } catch (error) {
                return false;
            }
        },
        async getLotTypes({ commit }, { queries = {} }) {
            try {
                let response = await axios.get(
                    `${this._vm.$commonAPI}/lot-types?limit=100`,
                    { params: queries }
                );
                return response.data.data;
            } catch (error) {
                return false;
            }
        },
        async getCountries({ commit }) {
            try {
                let response = await axios.get(
                    `${this._vm.$commonAPI}/countries?limit=200`
                );
                return response.data.data;
            } catch (error) {
                return false;
            }
        },
        async getStates({ commit }, { countryId = null }) {
            try {
                let response = await axios.get(
                    `${this._vm.$commonAPI}/states?limit=200`,
                    {
                        params: {
                            "country:id": countryId
                        }
                    }
                );
                return response.data.data;
            } catch (error) {
                return false;
            }
        },

        async getCities({ commit }, { stateId = null }) {
            try {
                let response = await axios.get(
                    `${this._vm.$commonAPI}/cities?limit=200`,
                    {
                        params: {
                            "state:id": stateId
                        }
                    }
                );
                return response.data.data;
            } catch (error) {
                return false;
            }
        },
        async getAreas({ commit }, { cityId = null }) {
            try {
                let response = await axios.get(
                    `${this._vm.$commonAPI}/areas?limit=200`,
                    {
                        params: {
                            "city:id": cityId
                        }
                    }
                );
                return response.data.data;
            } catch (error) {
                return false;
            }
        },
        async getBuildings({ commit }, { areaId = null }) {
            try {
                let response = await axios.get(
                    `${this._vm.$commonAPI}/buildings?limit=200`,
                    {
                        params: {
                            "area:id": areaId
                        }
                    }
                );
                return response.data.data;
            } catch (error) {
                return false;
            }
        },
        async getStreets({ commit }, { areaId = null, name = null }) {
            try {
                let response = await axios.get(
                    `${this._vm.$commonAPI}/streets?limit=10`,
                    {
                        params: {
                            "area:id": areaId,
                            "name[partial]": name
                        }
                    }
                );
                return response.data.data;
            } catch (error) {
                return false;
            }
        },
        async getPropertyFacilities({ commit }) {
            try {
                let response = await axios.get(
                    `${this._vm.$commonAPI}/property-facilities?limit=100`
                );
                return response.data.data;
            } catch (error) {
                return false;
            }
        },
        async getPropertyAmenities({ commit }) {
            try {
                let response = await axios.get(
                    `${this._vm.$commonAPI}/property-amenities?limit=100`
                );
                return response.data.data;
            } catch (error) {
                return false;
            }
        },
        async getPropertyCustomCategories({ commit }) {
            try {
                let response = await axios.get(
                    `${this._vm.$commonAPI}/property-custom-categories?limit=100`
                );
                return response.data.data;
            } catch (error) {
                return false;
            }
        },
        async getPropertyTags({ commit }) {
            try {
                let response = await axios.get(
                    `${this._vm.$commonAPI}/hashtags?limit=100`
                );
                return response.data.data;
            } catch (error) {
                return false;
            }
        }
    }
};
