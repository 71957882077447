<template>
  <div class="no-data">
    <div class="text-center mb-5">
      <img
        class="no-data-pic mt-5"
        :src="noDataSVG"
        alt="No Data"
        :style="{ width: imgSize, height: imgSize }"
      />
      <p class="mt-4 text-center no-data-message">{{ message }}</p>
      <button v-if="showBtn" class="btn main mt-3" @click="$emit('btnClick')">
        {{ btnText }}
      </button>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import noDataSVG from "@/assets/image/illustration/no_data.svg";

export default {
  components: {},
  mixins: [],
  props: {
    message: {
      type: String,
      default: "There is no data"
    },
    btnText: {
      type: String,
      default: "Go home"
    },
    showBtn: {
      type: Boolean,
      default: false
    },
    imgSize: {
      type: String,
      default: "150px"
    }
  },
  data: function () {
    return {
      noDataSVG: noDataSVG
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style scoped>
.no-data-pic {
}
.no-data-message {
  font-size: 20px;
  font-weight: 600;
}
</style>
