import axios from "axios";

export default {
  namespaced: true,
  state: () => {
    return {};
  },
  getters: {},
  mutations: {},
  actions: {
    async getAllBuildings({ commit }, payload) {
      try {
        let include = "country,state,city,area,street,parentBuilding";

        let response = await axios.get(
          `${this._vm.$commonAPI}/buildings`,
          {
            params: {
              ...payload,
              include: include
            }
          }
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    async getBuilding({ commit }, id) {
      try {
        let include = "country,state,city,area,street,parentBuilding";

        let response = await axios.get(
          `${this._vm.$commonAPI}/buildings/${id}` + `?include=${include}`
        );

        return response.data;
      } catch (error) {
        throw error;
      }
    }
  }
};
