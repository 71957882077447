<template>
  <div id="app">
    <router-view />
    <loader v-show="isLoading"></loader>
    <!-- vue-notification -->
    <notifications class="m-2" group="alert" position="top center" :max="3" />

    <PortalTarget name="dropdown-list" class="dropdown-portal"></PortalTarget>
  </div>
</template>

<script>
import Loader from "@/components/GlobalComponents/LoaderComponent/Loader";
import "boxicons/css/boxicons.min.css";
import "@/assets/GlobalStyling/static-css/material-colors/colors.css";
import "remixicon/fonts/remixicon.css";

/* import firebase from "firebase/app";
import messaging from "firebase/messaging"; */

export default {
  name: "App",
  components: {
    Loader
  },
  computed: {
    isLoading() {
      return this.$store.getters.getIsLoading;
    }
  }
};
</script>

<style lang="scss">
// Vue Notification overwrite
#app {
  .vue-notification {
    padding: 15px 20px;
    margin: 0 5px 5px;
    border-radius: 4px;
    font-size: 12px;
    color: #ffffff;

    background: $color-info;
    border: none;

    .notification-title {
      font-size: 14px;
    }

    &.warn {
      background: $color-warn;
    }

    &.error {
      background: $color-danger;
    }

    &.success {
      background: $color-success;
    }
  }
}

.dropdown-portal {
  .dropdown-wrapper {
    z-index: 99999;
    position: fixed;
    background: transparent;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    cursor: default;

    .dropdown-container {
      z-index: 3;
      background-color: white;
      position: absolute;
      width: max-content;
      height: fit-content;
    }
  }
}
</style>
