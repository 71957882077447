import axios from "axios";

export default {
    namespaced: true,
    state: () => {
        return {};
    },
    getters: {},
    mutations: {},
    actions: {
        async getAllStates({ commit }, payload) {
            try {
                let include;

                let response = await axios.get(
                    `${this._vm.$commonAPI}/states`,
                    {
                        params: {
                            ...payload,
                            include: include
                        }
                    }
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        async getState({ commit }, id) {
            try {
                let include = "country";

                let response = await axios.get(
                    `${this._vm.$commonAPI}/states/${id}`,
                    {
                        params: StateModel.getStatePayload({ include })
                    }
                );

                return StateModel.getStateResponse(response.data);
            } catch (error) {
                throw error;
            }
        },
        async createStates({ commit }, content) {
            try {
                const request = await axios.post(
                    `${this._vm.$adminAPI}/states`,
                    content
                );

                return request.data;
            } catch (error) {
                throw error;
            }
        },
        async updateStates({ commit }, content) {
            commit("setIsLoading", true, { root: true });

            try {
                const request = await axios.put(
                    `${this._vm.$adminAPI}/states/${content.id}`,
                    content.data
                );

                commit("setIsLoading", false, { root: true });
                return request.data;
            } catch (error) {
                commit("setIsLoading", false, { root: true });
                throw error;
            }
        },
        async deleteStates({ commit }, id) {
            try {
                const request = await axios.delete(
                    `${this._vm.$adminAPI}/states/${id}`
                );

                return request;
            } catch (error) {
                throw error;
            }
        }
    }
};
