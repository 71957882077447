var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"fd-button",class:{ compact: _vm.compact, medium: _vm.medium },attrs:{"type":_vm.type,"disabled":_vm.disabled},on:{"click":function (e) {
      if (this$1.loadingEnabled && this$1.isLoading) {
      } else {
        this$1.$emit('click', e);
      }
    }}},[_c('div',{staticClass:"d-block"},[(_vm.loadingEnabled && _vm.isLoading)?_c('spinner',{staticClass:"d-inline-block mr-1",attrs:{"size":_vm.spinnerSize,"color":_vm.spinnerColor}}):_vm._e(),(_vm.icon && !_vm.isLoading)?_c('i',{class:(_vm.icon + " mr-1")}):_vm._e(),_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }