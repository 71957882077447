<template>
  <div
    class="fd-input fd-validation"
    :class="{ error: hasError, 'shake-horizontal': hasError }"
  >
    <label v-if="label" :for="name" class="label"
      >{{ label }} <span style="color: red" v-if="isRequired">*</span>
    </label>
    <v-popover
      :disabled="!validationTooltip"
      :open="hasError"
      :auto-hide="false"
      trigger="manual"
      placement="left"
    >
      <div
        class="position-relative w-100"
        :class="{ 'd-flex': $slots['post-input-box'] }"
      >
        <the-mask
          v-if="mask"
          :value="value"
          :name="name"
          :type="type"
          :placeholder="placeholder"
          @change="emitChange"
          @input="onInput"
          @blur="onBlur"
          @keyup="onKeyup"
          :required="required"
          :readonly="readonly"
          :disabled="disabled"
          :title="title"
          :mask="mask"
          :masked="masked"
          @wheel="wheelEvent"
        >
        </the-mask>
        <input
          v-else
          :value="value"
          :name="name"
          :type="type"
          :placeholder="placeholder"
          @change="emitChange"
          @input="onInput"
          @blur="onBlur"
          @keyup="onKeyup"
          :required="required"
          :readonly="readonly"
          :disabled="disabled"
          :pattern="pattern"
          :title="title"
          :step="step"
          :min="min"
          :max="max"
          @wheel="wheelEvent"
          @keypress="onKeyPress"
        />
        <span class="append-icon">
          <slot name="append-icon"> </slot>
        </span>
        <span v-if="$slots['post-input-box']" class="d-inline-block">
          <slot name="post-input-box"></slot>
        </span>
      </div>
      <template slot="popover">
        <p v-close-popover>
          {{ errorMessage }}
        </p>
      </template>
    </v-popover>
    <!-- Validation error message -->
    <span v-if="hasError && !validationTooltip" class="errorMsg">{{
      errorMessage
    }}</span>
  </div>
</template>

<script>
import validator from "./Validator/mixin/validatorMixin";
import { TheMask } from "vue-the-mask";

export default {
  components: { TheMask },
  mixins: [validator],
  props: {
    pattern: null,
    title: null,
    value: {
      type: [String, Number],
      default: "",
      required: true
    },
    name: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: "text"
    },
    step: {
      type: String,
      default: "any"
    },
    min: {
      type: [Number, String],
      default: null
    },
    max: {
      type: [Number, String],
      default: null
    },
    label: {
      type: String
    },
    placeholder: {
      type: String
    },
    required: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    mask: {
      type: String,
      default: null
    },
    masked: {
      type: Boolean,
      default: false
    },
    inputFilter: {
      type: Function
    },
    validationTooltip: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      isValidationTooltipShown: false,
      maskedValue: ""
    };
  },
  computed: {},
  watch: {
    value: {
      handler() {
        this.hasError = false;
      },
      deep: true
    }
  },
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    unmask(maskedValue, mask) {
      let defaultTokens = ["#", "X", "S", "A", "a", "!"];
      let unmaskedValue = "";
      let isToken;

      for (let i = 0; i < maskedValue.length; i++) {
        isToken = false;
        for (let j = 0; j < defaultTokens.length; j++) {
          if (mask[i] == defaultTokens[j]) {
            isToken = true;
          }
        }

        if (isToken) {
          unmaskedValue += maskedValue[i];
        }
      }

      return unmaskedValue;
    },
    wheelEvent(event) {
      if (event.target.type === "number") {
        event.target.blur();
      }
    },
    onKeyPress(event) {
      if (this.inputFilter !== undefined) {
        if (!this.inputFilter(event.key)) {
          event.preventDefault();
        }
      }
    },
    onInput(event) {
      this.hasError = false;

      this.mask
        ? this.$emit("input", event)
        : this.$emit("input", event.target.value);
    },
    onBlur(event) {
      this.$emit("input", event.target.value.trim());
    },
    onKeyup(event) {
      this.$emit("keyup", event.target.value);
    },
    emitChange(event) {
      this.$emit("change", event);
    }
  }
};
</script>

<style lang="scss">
.tooltip {
  &.popover {
    .popover-inner {
      background: $color-secondary;
      padding: 10px 12px;
      border-radius: 5px;
      box-shadow: 0 5px 30px rgba(black, 0.1);
      p {
        color: white;
      }
    }

    .popover-arrow {
      border-color: $color-secondary;
    }
  }
}
.fd-input {
  .v-popover .trigger {
    width: 100%;
  }

  input {
    width: 100%;
    display: block;
    padding: 6px 12px;
    font-size: 14px;
    &:focus {
      transition: 0.25s;
      border: solid 1px $color-main;
    }
  }
  span.append-icon {
    display: flex;
    align-items: center;
    position: absolute;
    z-index: 1;
    font-size: 1.2em;
    color: #303030;
    right: 10px;
    top: 0;
    height: 100%;
  }

  &.error {
    $errorColor: #db4141;
    input {
      border: 2px solid $errorColor;
    }

    .errorMsg {
      color: $errorColor;
    }
  }
}

.label {
  margin-bottom: 4px;
  color: #5f5f5f;
  display: block;
}
</style>
