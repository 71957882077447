export default [
    /* =================================================================
     *                     Manage Agency Project Sale
     * =================================================================
     */
    {
        path: "/projects/sales",
        name: "ManageProjectSales",
        component: () => import("@/views/manageProjectSales"),
        redirect: { name: "ManageProjectSalesOverview" },
        meta: {
            auth: true,
            permission: "projectPurchase.manage"
        },
        children: [
            {
                path: "overview",
                name: "ManageProjectSalesOverview",
                component: () => import("@/views/manageProjectSales/overview"),
                meta: {
                    auth: true,
                    permission: "projectPurchase.manage"
                }
            },
            // ==================== Reservations ====================
            {
                path: "manage-reservation",
                name: "ProjectReservationsManagement",
                component: {
                    render: (c) => c("router-view")
                },
                redirect: { name: "ViewReservedUnits" },
                children: [
                    {
                        path: "reserved-units",
                        name: "ViewReservedUnits",
                        component: () =>
                            import(
                                "@/views/manageProjectReservations/reservedUnits"
                            ),
                        meta: {
                            auth: true,
                            permission: "projectPurchase.manage"
                        }
                    },
                    {
                        path: "unit/:id/reservation",
                        name: "ManageProjectReservations",
                        component: () =>
                            import("@/views/manageProjectReservations"),
                        meta: {
                            auth: true,
                            permission: "projectPurchase.manage"
                        }
                    },
                    {
                        path: "unit/:unitId/reservation/:id",
                        name: "ManageProjectReservationDetails",
                        component: () =>
                            import("@/views/manageProjectReservations/details"),
                        meta: {
                            auth: true,
                            permission: "projectPurchase.manage"
                        }
                    }
                ]
            },
            // ==================== Bookings ====================
            {
                path: "manage-bookings",
                name: "ManageProjectBooking",
                component: () => import("@/views/manageProjectBookings"),
                meta: {
                    auth: true,
                    permission: "projectPurchase.manage"
                },
                redirect: { name: "ManageProjectBookingPending" },
                children: [
                    {
                        path: "pending",
                        name: "ManageProjectBookingPending",
                        component: () =>
                            import(
                                "@/views/manageProjectBookings/pendingBooking"
                            ),
                        meta: {
                            auth: true,
                            permission: "projectPurchase.manage"
                        }
                    },
                    {
                        path: "rejected",
                        name: "ManageProjectBookingRejected",
                        component: () =>
                            import(
                                "@/views/manageProjectBookings/rejectedBooking"
                            ),
                        meta: {
                            auth: true,
                            permission: "projectPurchase.manage"
                        }
                    },
                    {
                        path: "booked-by-other",
                        name: "ManageProjectBookingBookedByOther",
                        component: () =>
                            import(
                                "@/views/manageProjectBookings/bookedByOtherBooking"
                            ),
                        meta: {
                            auth: true,
                            permission: "projectPurchase.manage"
                        }
                    },
                    {
                        path: ":id",
                        name: "ManageProjectBookingDetail",
                        component: () =>
                            import(
                                "@/views/manageProjectBookings/detail/_detail"
                            ),
                        meta: {
                            auth: true,
                            permission: "projectPurchase.manage"
                        }
                    }
                ]
            },
            // ======================== Mortgage =======================
            {
                path: "mortgage",
                name: "ManageProjectMortgage",
                redirect: { name: "ManageProjectMortgageBankLoan" },
                meta: {
                    auth: true,
                    permission: "projectPurchase.manage"
                }
            },
            {
                path: "mortgage/bank-loan",
                name: "ManageProjectMortgageBankLoan",
                component: () =>
                    import("@/views/manageProjectMortgage/bankLoan"),
                meta: {
                    auth: true,
                    permission: "projectPurchase.manage"
                }
            },
            {
                path: "mortgage/bank-loan/:id(\\d+)",
                name: "ManageProjectMortgageBankLoansDetail",
                component: () =>
                    import(
                        "@/views/manageProjectMortgage/bankLoan/detail/_detail"
                    ),
                meta: {
                    auth: true,
                    permission: "projectPurchase.manage"
                }
            },
            {
                path: "mortgage/bank-loan-history",
                name: "ManageProjectMortgageBankLoanHistory",
                component: () =>
                    import("@/views/manageProjectMortgage/bankLoan/history"),
                meta: {
                    auth: true,
                    permission: "projectPurchase.manage"
                }
            },
            {
                path: "mortgage/other",
                name: "ManageProjectMortgageOther",
                component: () => import("@/views/manageProjectMortgage/other"),
                meta: {
                    auth: true,
                    permission: "projectPurchase.manage"
                }
            },
            {
                path: "mortgage/other/:id",
                name: "ManageProjectMortgageOtherDetail",
                component: () =>
                    import(
                        "@/views/manageProjectMortgage/other/detail/_detail"
                    ),
                meta: {
                    auth: true,
                    permission: "projectPurchase.manage"
                }
            },
            // ======================== Lawyer =========================
            {
                path: "legal-process",
                name: "ManageProjectLegalProcess",
                component: () => import("@/views/manageProjectLegalProcess"),
                meta: {
                    auth: true,
                    permission: "projectPurchase.manage"
                }
            },
            {
                path: "legal-process/:id",
                name: "ManageProjectLegalProcessDetail",
                component: () =>
                    import("@/views/manageProjectLegalProcess/detail/_detail"),
                meta: {
                    auth: true,
                    permission: "projectPurchase.manage"
                }
            },
            // =================== Vacant Possession ===================
            {
                path: "vacant-posession",
                name: "ManageProjectVacantPossession",
                component: () => import("@/views/manageProjectVacantPosession"),
                meta: {
                    auth: true,
                    permission: "projectPurchase.manage"
                }
            },
            {
                path: "vacant-posession/:id",
                name: "ManageProjectVacantPossessionDetails",
                component: () =>
                    import(
                        "@/views/manageProjectVacantPosession/detail/_detail"
                    ),
                meta: {
                    auth: true,
                    permission: "projectPurchase.manage"
                }
            },
            // ========================= Refund ========================
            {
                path: "refund",
                name: "ManageProjectBookingRefunds",
                component: () => import("@/views/manageProjectBookingRefunds"),
                meta: {
                    auth: true,
                    permission: "projectPurchase.manage"
                }
            },
            {
                path: "refund/:id(\\d+)",
                name: "ProjectRefundDetails",
                component: () =>
                    import(
                        "@/views/manageProjectBookingRefunds/detail/_detail"
                    ),
                meta: {
                    auth: true,
                    permission: "projectPurchase.manage"
                }
            },
            // Refund history
            {
                path: "refund/history",
                name: "ProjectRefundHistory",
                component: () =>
                    import(
                        "@/views/manageProjectBookingRefunds/history/_history"
                    ),
                meta: {
                    auth: true,
                    permission: "projectPurchase.manage"
                }
            },
            {
                path: "refund/history/:id",
                name: "ProjectRefundHistoryDetails",
                component: () =>
                    import(
                        "@/views/manageProjectBookingRefunds/history/_detail"
                    ),
                meta: {
                    auth: true,
                    permission: "projectPurchase.manage"
                }
            },
            // =========================================================
            {
                path: ":id",
                name: "ProjectSalesDetail",
                component: () => import("@/views/projectSales/_detail.vue"),
                meta: {
                    auth: true,
                    permission: "projectPurchase.manage"
                }
            }
        ]
    },

    // Sales Report
    {
        path: "projects/:id(\\d+)/sales/report",
        name: "ProjectSalesReport",
        component: () =>
            import("@/views/manageProjectSalesReport/detail/_detail"),
        meta: {
            auth: true,
            permission: "projectPurchase.manage"
        }
    },

    /* =========================================================================
     *                       Manage Assigned Project Sale
     * =========================================================================
     */
    {
        path: "/projects/assigned/sales/",
        name: "ManageAssignedProjectSales",
        component: () => import("@/views/manageProjectSales/assigned"),
        redirect: { name: "ManageAssignedProjectSalesOverview" },
        meta: {
            auth: true
        },
        children: [
            {
                path: "overview",
                name: "ManageAssignedProjectSalesOverview",
                component: () => import("@/views/manageProjectSales/overview"),
                meta: {
                    auth: true
                },
                props: {
                    isAssigned: true
                }
            },
            // ==================== Reservations ====================
            {
                path: "manage-reservation",
                name: "AssignedProjectReservationsManagement",
                component: {
                    render: (c) => c("router-view")
                },
                redirect: { name: "ViewAssignedReservedUnits" },
                children: [
                    {
                        path: "reserved-units",
                        name: "ViewAssignedReservedUnits",
                        component: () =>
                            import(
                                "@/views/manageProjectReservations/reservedUnits"
                            ),
                        meta: {
                            auth: true
                        },
                        props: {
                            isAssigned: true
                        }
                    },
                    {
                        path: "unit/:id/reservation",
                        name: "ManageAssignedProjectReservations",
                        component: () =>
                            import("@/views/manageProjectReservations"),
                        meta: {
                            auth: true
                        },
                        props: {
                            isAssigned: true
                        }
                    },
                    {
                        path: "unit/:unitId/reservation/:id",
                        name: "ManageAssignedProjectReservationDetails",
                        component: () =>
                            import("@/views/manageProjectReservations/details"),
                        meta: {
                            auth: true
                        },
                        props: {
                            isAssigned: true
                        }
                    }
                ]
            },
            // ==================== Bookings ====================
            {
                path: "manage-bookings",
                name: "ManageAssignedProjectBooking",
                component: () => import("@/views/manageProjectBookings"),
                meta: {
                    auth: true
                },
                props: {
                    isAssigned: true
                },
                redirect: { name: "ManageAssignedProjectBookingPending" },
                children: [
                    {
                        path: "pending",
                        name: "ManageAssignedProjectBookingPending",
                        component: () =>
                            import(
                                "@/views/manageProjectBookings/pendingBooking"
                            ),
                        meta: {
                            auth: true
                        },
                        props: {
                            isAssigned: true
                        }
                    },
                    {
                        path: "rejected",
                        name: "ManageAssignedProjectBookingRejected",
                        component: () =>
                            import(
                                "@/views/manageProjectBookings/rejectedBooking"
                            ),
                        meta: {
                            auth: true
                        },
                        props: {
                            isAssigned: true
                        }
                    },
                    {
                        path: "booked-by-other",
                        name: "ManageAssignedProjectBookingBookedByOther",
                        component: () =>
                            import(
                                "@/views/manageProjectBookings/bookedByOtherBooking"
                            ),
                        meta: {
                            auth: true
                        },
                        props: {
                            isAssigned: true
                        }
                    },
                    {
                        path: ":id",
                        name: "ManageAssignedProjectBookingDetail",
                        component: () =>
                            import(
                                "@/views/manageProjectBookings/detail/_detail"
                            ),
                        meta: {
                            auth: true
                        },
                        props: {
                            isAssigned: true
                        }
                    }
                ]
            },
            // ======================== Mortgage =======================
            {
                path: "mortgage",
                name: "ManageAssignedProjectMortgage",
                redirect: { name: "ManageAssignedProjectMortgageBankLoan" },
                meta: {
                    auth: true
                }
            },
            {
                path: "mortgage/bank-loan",
                name: "ManageAssignedProjectMortgageBankLoan",
                component: () =>
                    import("@/views/manageProjectMortgage/bankLoan"),
                meta: {
                    auth: true
                },
                props: {
                    isAssigned: true
                }
            },
            {
                path: "mortgage/bank-loan/:id(\\d+)",
                name: "ManageAssignedProjectMortgageBankLoansDetail",
                component: () =>
                    import(
                        "@/views/manageProjectMortgage/bankLoan/detail/_detail"
                    ),
                meta: {
                    auth: true
                },
                props: {
                    isAssigned: true
                }
            },
            {
                path: "mortgage/bank-loan/history",
                name: "ManageAssignedProjectMortgageBankLoanHistory",
                component: () =>
                    import("@/views/manageProjectMortgage/bankLoan/history"),
                meta: {
                    auth: true
                },
                props: {
                    isAssigned: true
                }
            },
            {
                path: "mortgage/other",
                name: "ManageAssignedProjectMortgageOther",
                component: () => import("@/views/manageProjectMortgage/other"),
                meta: {
                    auth: true
                },
                props: {
                    isAssigned: true
                }
            },
            {
                path: "mortgage/other/:id",
                name: "ManageAssignedProjectMortgageOtherDetail",
                component: () =>
                    import(
                        "@/views/manageProjectMortgage/other/detail/_detail"
                    ),
                meta: {
                    auth: true
                },
                props: {
                    isAssigned: true
                }
            },
            // ======================== Lawyer =========================
            {
                path: "legal-process",
                name: "ManageAssignedProjectLegalProcess",
                component: () => import("@/views/manageProjectLegalProcess"),
                meta: {
                    auth: true
                },
                props: {
                    isAssigned: true
                }
            },
            {
                path: "legal-process/:id",
                name: "ManageAssignedProjectLegalProcessDetail",
                component: () =>
                    import("@/views/manageProjectLegalProcess/detail/_detail"),
                meta: {
                    auth: true
                },
                props: {
                    isAssigned: true
                }
            },
            // =================== Vacant Possession ===================
            {
                path: "vacant-posession",
                name: "ManageAssignedProjectVacantPossession",
                component: () => import("@/views/manageProjectVacantPosession"),
                meta: {
                    auth: true
                },
                props: {
                    isAssigned: true
                }
            },
            {
                path: "vacant-posession/:id",
                name: "ManageAssignedProjectVacantPossessionDetails",
                component: () =>
                    import(
                        "@/views/manageProjectVacantPosession/detail/_detail"
                    ),
                meta: {
                    auth: true
                },
                props: {
                    isAssigned: true
                }
            },
            // =========================================================
            {
                path: ":id",
                name: "AssignedProjectSalesDetail",
                component: () => import("@/views/projectSales/_detail.vue"),
                meta: {
                    auth: true
                },
                props: {
                    isAssigned: true
                }
            }
        ]
    }
];
