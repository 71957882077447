import Vue from "vue";
import ERR_CODE from "@/utils/error/apiErrorCode";

export function errorHandler(error) {
    let errData = error.response.data;

    if (isRecognizedError(errData.exceptionCode)) {
        handleOutdatedApp(errData.exceptionCode);
        handleJWTInValidity(errData);
        return Promise.reject(error);
    } else {
        handleUserBadRequestError(errData.code);
        handleUserNotFoundError(errData.code);
        handleServerError(errData.code);
        return Promise.reject(error);
    }
}

async function handleOutdatedApp(code) {
    if (code == ERR_CODE.OUTDATED_APP) {
        // Prompt refresh
        let confirm = await Vue.prototype.$confirm({
            title: "New Update Available",
            message: "Please refresh to get the new update.",
            persistent: true,
            showCancel: false,
            confirmText: "Refresh"
        });
        if (confirm) {
            window.location.reload();
        }
    }
}

function handleServerError(code) {
    if (code == 500) {
        Vue.prototype.$notify({
            group: "alert",
            type: "error",
            title: "Server Error",
            text: "Sorry, something went wrong with our server."
        });
    }
}

function handleUserNotFoundError(code) {
    if (code == 404) {
        Vue.prototype.$notify({
            group: "alert",
            type: "error",
            title: "Error",
            text: "Error during fetching or submitting data."
        });
    }
}

function handleUserBadRequestError(code) {
    if (code == 400) {
        Vue.prototype.$notify({
            group: "alert",
            type: "error",
            title: "Error",
            text: "Invalid data requested."
        });
    }
}

function handleJWTInValidity(data) {
    if (
        data.exceptionCode == ERR_CODE.JWT_INVALID ||
        data.exceptionCode == ERR_CODE.JWT_NOT_FOUND ||
        data.exceptionCode == ERR_CODE.JWT_EXPIRED
    ) {
        Vue.prototype.$notify({
            group: "alert",
            type: "error",
            text: data.message
        });

        Vue.prototype.$auth.logout({
            makeRequest: false
        });

        Vue.prototype.$router.push({ name: "Login" });
    }
}

function isRecognizedError(code) {
    return code == null || code == undefined || Object.keys(code).length === 0;
}
