import Vue from "vue";

import customizedTab from "@/components/GlobalComponents/CustomizedTab/Tab";
import customizedWindow from "@/components/GlobalComponents/CustomizedTab/TabWindow";
import FdTabs from "@/components/GlobalComponents/FdTabs/Tabs";
import FdTabsWindow from "@/components/GlobalComponents/FdTabs/TabWindow";
import FdRouteTab from "@/components/GlobalComponents/FdTabs/RouteTab";
import Modal from "@/components/GlobalComponents/ModalBox/Modal";
import ModalHeader from "@/components/GlobalComponents/ModalBox/ModalHeader";
import ModalBody from "@/components/GlobalComponents/ModalBox/ModalBody";
import ModalFooter from "@/components/GlobalComponents/ModalBox/ModalFooter";
import ModalContent from "@/components/GlobalComponents/ModalBox/ModalContent";

import FilterPanel from "@/components/GlobalComponents/Filter/FilterPanel";

import NoData from "@/components/GlobalComponents/NoData/NoData";

// Form Components
import FdButton from "@/components/GlobalComponents/FormComponents/FdButton";
import FdInput from "@/components/GlobalComponents/FormComponents/FdInput";
import FdTextarea from "@/components/GlobalComponents/FormComponents/FdTextarea";
import FdDate from "@/components/GlobalComponents/FormComponents/FdDate";
import FdDatePicker from "@/components/GlobalComponents/FormComponents/FdDatePicker";
import FdSelect from "@/components/GlobalComponents/FormComponents/FdSelect";
import FdMultiSelect from "@/components/GlobalComponents/FormComponents/FdMultiSelect";
import FdTagsInput from "@/components/GlobalComponents/FormComponents/FdTagsInput";
import FdVSelect from "@/components/GlobalComponents/FormComponents/FdVSelect";
import FdCheckbox from "@/components/GlobalComponents/FormComponents/FdCheckbox";
import FdFormSection from "@/components/GlobalComponents/FormComponents/FdFormSection";
import FdForm from "@/components/GlobalComponents/FormComponents/FdForm";

import draggable from "vuedraggable";

Vue.component("fd-button", FdButton);
Vue.component("fd-input", FdInput);
Vue.component("fd-textarea", FdTextarea);
Vue.component("fd-date", FdDate);
Vue.component("fd-date-picker", FdDatePicker);
Vue.component("fd-select", FdSelect);
Vue.component("fd-multi-select", FdMultiSelect);
Vue.component("fd-tags-input", FdTagsInput);
Vue.component("fd-v-select", FdVSelect);
Vue.component("fd-checkbox", FdCheckbox);
Vue.component("fd-form-section", FdFormSection);
Vue.component("fd-form", FdForm);

Vue.component("fd-tabs", FdTabs);
Vue.component("fd-tab-window", FdTabsWindow);
Vue.component("fd-route-tab", FdRouteTab);

Vue.component("customized-tab", customizedTab);
Vue.component("tab-window", customizedWindow);
Vue.component("modal", Modal);
Vue.component("modal-header", ModalHeader);
Vue.component("modal-body", ModalBody);
Vue.component("modal-footer", ModalFooter);
Vue.component("modal-content", ModalContent);
Vue.component("filter-panel", FilterPanel);
Vue.component("no-data", NoData);

Vue.component("vue-draggable", draggable);
