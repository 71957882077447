import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import qsStringify from "qs/lib/stringify";
import { cleanQueryParam } from "@/utils/filter";
import { errorHandler } from "@/utils/error/apiExceptionHandler";

Vue.use(VueAxios, axios);
Vue.prototype.$axios = axios;

axios.defaults.baseURL = process.env.VUE_APP_API_URL.replace(/\/$/, "");
axios.defaults.paramsSerializer = function (params) {
    params = cleanQueryParam(params);
    return qsStringify(params, { arrayFormat: "brackets" });
};

axios.defaults.headers.common = {
    "agent-cpanel-version": process.env.VUE_APP_VERSION
    /*"Access-Control-Allow-Origin" : '*'
    "Access-Control-Allow-Methods":"GET, POST, PATCH, PUT, DELETE, OPTIONS'",
    "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept, Authorization"*/
};

axios.interceptors.response.use(
    function (res) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        return Promise.resolve(res);
    },
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    errorHandler
);

export { axios };
