import { NotificationAPI } from "@/modules/Notification/api/notification";
import { agencyAPI } from "@/modules/api-config";

const notificationAPI = new NotificationAPI(agencyAPI, "Agency CP");

export default {
    state: {
        hasUnreadNotification: false
    },
    mutations: {
        setHasUnreadNotification(state, bool) {
            state.hasUnreadNotification = bool;
        }
    },
    getters: {
        getHasUnreadNotification(state) {
            return state.hasUnreadNotification;
        }
    },
    actions: {
        async checkHasUnreadNotification({ commit }) {
            let data = await notificationAPI.getNotifications({
                page: 1,
                perPage: 1,
                seen: false
            });
            commit("setHasUnreadNotification", data.data.length > 0);
        }
    }
};
