import store from "../../store";

export default async function permission({ to }) {
  if (to.matched.some((record) => record.meta.permission)) {
    let allowed = await store.dispatch(
      "manageAccount/checkPermission",
      to.meta.permission
    );
    return allowed;
  } else {
    return true;
  }
}
