export function jsonToArray(json, fn) {
  const arr = [];

  for (let x of Object.values(json)) {
    arr.push(fn(x));
  }

  return arr;
}

export function jsonArrayIncludes(array, regex, key) {
  if (array.length <= 0) {
    return false;
  } else if (typeof array[0] == typeof regex) {
    for (let x of array) {
      if (typeof regex == "string") {
        if (x[key].match(new RegExp(`^${regex}$`))) {
          return true;
        }
      } else {
        for (let y of Object.values(regex)) {
          if (x[key].match(new RegExp(`^${y}$`))) {
            return true;
          }
        }
      }
    }

    return false;
  } else {
    console.error(`Unable to compare ${typeof array[0]} with ${typeof regex}`);
    return false;
  }
}

export function extractStringFromJsonArray(jsonArray, key, fn) {
  const arr = [];

  for (let x of jsonArray) {
    arr.push(fn(x[key]));
  }

  return arr;
}

export const jsonUtils = {
  isJson(val) {
    if (
      typeof val == "object" &&
      val != null &&
      val != undefined &&
      !Array.isArray(val)
    ) {
      return true;
    } else {
      return false;
    }
  }
};
