<template>
  <div class="confirm-dialog">
    <modal :value="isShown" @close="$emit('close')" :persistent="persistent">
      <div class="card">
        <div class="confirm-body p-4">
          <div class="text-center mb-2">
            <fa-icon
              v-if="isAlert()"
              icon="exclamation-triangle"
              size="4x"
              :style="{ color: '#f43f41' }"
            />
          </div>
          <div class="title mb-1">
            {{ title }}
          </div>
          <div v-html="message"></div>
        </div>
        <div class="confirm-footer text-right p-2">
          <button
            v-if="showCancel"
            class="btn cancel mr-1"
            @click="$emit('cancel')"
          >
            {{ cancelText }}
          </button>
          <button
            :class="{ danger: isAlert(), main: type == 'basic' }"
            class="btn"
            @click="$emit('confirm')"
          >
            {{ confirmText }}
          </button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
/**
 * :type [String] Determine the type of modal, default is "basic".
 *    - available options: ["basic", "alert"]
 * :persistent [Boolean] To control whether the modal can be closed by clicking on overlay.
 * :title [String] To customize the title text of the confirmation modal.
 * :message [String] For displaying the confirmation message.
 * :confirmText [String] For customizing confirm button text.
 * :cancelText [String] For customizing cancel button text.
 *
 * @close Event triggered on modal close.
 * @confirm Event triggered when user clicks confirm button.
 * @cancel Event triggered when user clicks the cancel button.
 */
import Modal from "./Modal";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faExclamationTriangle);

import router from "@/router";

export default {
  components: {
    Modal,
    FaIcon: FontAwesomeIcon
  },
  mixins: [],
  props: {
    type: {
      type: String,
      default: "basic"
    },
    value: {
      type: Boolean,
      default: false
    },
    persistent: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: "Are you sure?"
    },
    message: {
      type: String,
      default: ""
    },
    confirmText: {
      type: String,
      default: "Confirm"
    },
    showCancel: {
      type: Boolean,
      default: true
    },
    cancelText: {
      type: String,
      default: "Cancel"
    }
  },
  data: function () {
    return {
      isShown: false
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    router.beforeEach((to, from, next) => {
      this.close();
      next();
    });
  },
  methods: {
    open() {
      return (this.isShown = true);
    },
    async close() {
      return (this.isShown = false);
    },
    isAlert() {
      return this.type == "alert";
    },
    isBasic() {
      return this.type == "basic";
    }
  }
};
</script>

<style lang="scss">
.confirm-dialog {
  .title {
    font-size: 20px;
    font-weight: 600;
    color: #5f5f5f;
  }

  .confirm-body {
    color: #5f5f5f;
    font-size: 14px;
  }

  .btn.cancel {
    background: #ffffff;
    color: #a9a9a9;
    &:hover {
      background: #efefef;
    }
    &:focus {
      background: #e6e6e6;
    }
  }
}
</style>
