<template>
  <div
    class="fd-select fd-validation"
    :class="{ error: hasError, 'shake-horizontal': hasError }"
  >
    <label for="name" class="label"
      >{{ label }}
      <span style="color: red" v-if="isRequired && label != null"
        >*</span
      ></label
    >
    <div class="d-flex align-items-center">
      <div class="select-wrapper w-100">
        <select
          :name="name"
          v-model="selectedOption"
          :required="required"
          :disabled="disabled"
          @change="onChange(selected)"
        >
          <slot></slot>
          <option
            v-if="showEmptyChoice"
            value=""
            :selected="value == '' || value == null"
          >
            {{ selectText }}
          </option>
          <option
            v-for="(option, index) in options"
            :key="index"
            :value="optionValue(option)"
            :selected="isValue(option)"
            :disabled="isOptionDisabled(option)"
          >
            {{ optionLabel(option) }}
          </option>
        </select>
        <span
          v-if="clearable"
          class="clearable cursor-pointer"
          @click="clearSelect"
        >
          <i class="fas fa-times"></i>
        </span>
      </div>
      <spinner v-if="isLoading" class="mx-2" size="16px"></spinner>
    </div>

    <!-- Validation error message -->
    <span v-if="hasError" class="errorMsg">{{ errorMessage }}</span>
  </div>
</template>

<script>
/**
 * Select dropdown component.
 *
 * :label [String] Set the label of the select input
 * :options [Array] An array of objects to provide the options available to select.
 * :optionValue [Function] A function to set the value for the options. For example see the prop default value.
 * :optionLabel [Function] A function to set the label for the options. For example see the prop default value.
 * :selectText [String] Set the default select text hint.
 * :name [String] Name for the form input.
 * :required [Boolean] Determine if the input is a required field.
 * :disabled [Boolean] Determine if the select input is disabled.
 */

import validator from "./Validator/mixin/validatorMixin";

export default {
  name: "FdSelect",
  mixins: [validator],
  components: {
    Spinner: () =>
      import("@/components/GlobalComponents/LoaderComponent/Spinner")
  },
  props: {
    label: {
      type: String
    },
    options: {
      type: Array,
      default: () => {
        [];
      },
      required: true
    },
    optionValue: {
      type: Function,
      default: (option) => {
        return option.id;
      }
    },
    optionKey: {
      type: Function,
      default: null
    },
    optionLabel: {
      type: Function,
      default: (option) => {
        return option.name;
      }
    },
    isOptionDisabled: {
      type: Function,
      default: (option) => option.disabled
    },
    showEmptyChoice: {
      type: Boolean,
      default: true
    },
    selectText: {
      type: String,
      default: "Choose an option"
    },
    name: {
      type: String
    },
    value: {
      default: ""
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedOption: ""
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (val != this.selectedOption) {
          this.selectedOption = val;
        }
      }
    }
  },
  computed: {
    selected() {
      return this.selectedOption;
    }
  },
  methods: {
    onChange(val) {
      this.hasError = false;
      this.$emit("change", val);
      this.$emit("input", val);
    },
    clearSelect() {
      this.$emit("input", null);
    },
    isValue(option) {
      return Object.is(this.optionValue(option), this.value);
    }
  }
};
</script>

<style lang="scss">
.fd-select {
  position: relative;
  display: block;
  width: 100%;
  #star {
    padding: 0 4px;
    color: red;
  }
  .select-wrapper {
    position: relative;
    &:after {
      content: "\f10c";
      font-family: "Flaticon";
      position: absolute;
      top: 45%;
      font-size: 8px;
      right: 15px;
      color: $color-14;
    }
  }
  select {
    border: 1px solid #ddd;
    padding: 6px 30px 6px 12px;
    border-radius: 4px;
    transition: 0.3s;
    font-size: 14px;
    outline: none;
    width: 100%;
    color: #5f5f5f;
    text-overflow: ellipsis;
    option {
      background-color: white;
      color: #5f5f5f;
    }
    &:active,
    &:focus {
      border: 1px solid $color-main;
    }
    &:disabled {
      background-color: darken(white, 3);
    }
  }
  span.clearable {
    float: right;
    margin-right: 30px;
    margin-top: -30px;
    position: relative;
    z-index: 1;
    font-size: 1.2em;
    color: #5f5f5f;
  }
  .label {
    margin-bottom: 4px;
    color: #5f5f5f;
    display: block;
  }

  &.error {
    $errorColor: #db4141;
    select {
      border: 2px solid $errorColor;
    }
    .errorMsg {
      color: $errorColor;
    }
  }
}
</style>
