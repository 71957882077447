<template>
  <div ref="fdTabWindow" class="fd-tab-window">
    <div v-if="isActive" class="window" :name="name">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "fd-tab-window",
  props: {
    name: String,
    label: String,
    iconClass: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      isActive: false
    };
  },
  updated() {
    this.$parent.$emit("update");
  }
};
</script>

<style lang="scss"></style>
