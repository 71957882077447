import { cloneDeep } from "lodash";

export function cleanQueryParam(obj) {
    let cleanObj = cloneDeep(obj);
    for (let val in cleanObj) {
        if (cleanObj[val] === "" || cleanObj[val] === null) {
            delete cleanObj[val];
        }
    }
    return cleanObj;
}

export function isFilterEmpty(obj) {
    for (let key in obj) {
        if (obj[key] != "") return false;
    }
    return true;
}
