<template>
  <div
    ref="multiselect"
    class="fd-multi-select fd-validation"
    :class="{ error: hasError, 'shake-horizontal': hasError }"
  >
    <!-- Label -->
    <label class="label"
      >{{ label }} <span style="color: red" v-if="isRequired">*</span></label
    >
    <!-- Dropdown -->
    <div class="dropdown" @click="toggleDropdown">
      <div class="overselect"></div>
      <div v-if="value != null && value.length > 0" class="chosen-options">
        <span
          class="option-text"
          v-for="(selected, index) in value"
          :key="index"
        >
          {{ getSelectedLabel(selected) }}
        </span>
      </div>
      <select v-else class="select-input">
        <option :value="null">
          {{ selectText }}
        </option>
      </select>
    </div>
    <div class="multiselect" v-if="show">
      <ul class="option-panel">
        <li v-for="(option, index) in options" :key="index" class="option">
          <input
            class="mr-1"
            type="checkbox"
            :id="index"
            :value="getValue(option)"
            @change="onChange(option)"
            :checked="isChecked(option)"
          />
          <!-- v-model="selected" -->
          <label :for="index">{{ getLabel(option) }}</label>
        </li>
      </ul>
    </div>
    <!-- Validation with error message -->
    <span v-if="hasError" class="errorMsg">{{ errorMessage }}</span>
  </div>
</template>

<script>
import { jsonArrayIncludes } from "@/utils/array";

import validator from "./Validator/mixin/validatorMixin";

export default {
  name: "fd-multi-select",
  mixins: [validator],
  props: {
    value: {
      type: Array
    },
    label: {
      type: String
    },
    selectText: {
      type: String,
      default: "Please select one"
    },
    options: {
      type: Array,
      default: () => {
        [];
      }
    },
    required: {
      type: Boolean,
      default: false
    },
    optionValueKey: {
      type: String,
      default: null,
      required: false
    },
    optionLabelKey: {
      type: String,
      default: null,
      required: false
    }
  },
  mounted() {
    window.addEventListener("click", this.closeDropdown);
  },
  beforeDestroy() {
    window.removeEventListener("click", this.closeDropdown);
  },
  data() {
    return {
      show: false
    };
  },
  methods: {
    getLabel(option) {
      if (this.optionLabelKey != null) {
        return option[this.optionLabelKey];
      } else {
        return option;
      }
    },
    getSelectedLabel(value) {
      if (this.optionLabelKey != null) {
        let selected = this.options.find((option) => {
          return option[this.optionValueKey] == this.getValue(value);
        });
        return selected[this.optionLabelKey];
      } else {
        return value;
      }
    },
    getValue(option) {
      if (this.optionValueKey != null) {
        return option[this.optionValueKey];
      } else {
        return option;
      }
    },
    toggleDropdown() {
      this.show = !this.show;
    },
    closeDropdown(e) {
      if (!this.$refs.multiselect.contains(e.target)) {
        this.show = false;
      }
    },
    isChecked(option) {
      if (typeof option == "string") {
        return this.value.includes(option);
      } else {
        return jsonArrayIncludes(this.value, option, this.optionValueKey);
      }
    },
    onChange(value) {
      this.hasError = false; // Set error message to false
      let data = [];
      if (typeof value == "string") {
        data = this.stringTypeOnChange(value);
      } else {
        data = this.objectTypeOnChange(value);
      }

      this.$emit("input", data);
    },
    stringTypeOnChange(value) {
      if (this.value.includes(value)) {
        return this.removeElem(value);
      } else {
        let copy = this._.cloneDeep(this.value);
        copy.push(value);
        return copy;
      }
    },
    objectTypeOnChange(value) {
      if (jsonArrayIncludes(this.value, value, this.optionValueKey)) {
        return this.removeElem(value);
      } else {
        let temp = {};
        temp[this.optionValueKey] = this.getValue(value);
        let copy = this._.cloneDeep(this.value);
        copy.push(temp);
        return copy;
      }
    },
    removeElem(value) {
      for (let x = 0; x < this.value.length; x++) {
        if (this.getValue(this.value[x]) == this.getValue(value)) {
          let copy = this._.cloneDeep(this.value);
          copy.splice(x, 1);
          return copy;
        }
      }
    }
  }
};
</script>

<style lang="scss">
.fd-multi-select {
  height: fit-content;
  color: #5f5f5f;

  label {
    &.label {
      margin-bottom: 4px;
      color: #5f5f5f;
      display: block;
    }
  }
  .dropdown {
    position: relative;
    cursor: pointer;

    select {
      width: 100%;
      color: #5f5f5f;
    }

    .overselect {
      &:after {
        content: "\f10c";
        font-family: "Flaticon";
        position: absolute;
        top: 45%;
        font-size: 8px;
        right: 15px;
        color: $color-14;
      }
    }

    .chosen-options {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 14px;
      border: solid 1px #ddd;
      border-radius: 4px;
      padding: 6px 22px 6px 12px;
      .option-text:not(:last-child)::after {
        content: ",";
      }
    }
  }

  .multiselect {
    position: relative;

    ul.option-panel {
      border: 1px solid #ddd;
      background: white;
      border-radius: 0 0 3px 3px;
      left: 0px;
      padding: 8px 8px;
      position: absolute;
      top: -1rem;
      width: 100%;
      list-style: none;
      max-height: 150px;
      overflow: auto;
      font-size: 14px;
      box-shadow: 0px 5px 10px -5px #00000015;
      z-index: 2;
      .option {
        display: flex;
        align-items: center;
        cursor: pointer;
        input[type="checkbox"] {
          zoom: 1.4;
        }
        label {
          width: 100%;
          cursor: pointer;
        }
      }
    }
  }

  &.error {
    $errorColor: #db4141;
    .overselect {
      border: 2px solid $errorColor;
      border-radius: 4px;
    }
    .errorMsg {
      color: $errorColor;
    }
  }
}

.overselect {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>