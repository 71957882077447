var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fd-v-select fd-validation",class:{ error: _vm.hasError, 'shake-horizontal': _vm.hasError }},[_c('label',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.isRequired && _vm.label != null)?_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]):_vm._e()]),_c('v-select',_vm._b({attrs:{"value":_vm.value,"options":_vm.options,"getOptionLabel":_vm.optionLabel,"getOptionKey":_vm.optionKey,"reduce":_vm.optionValue,"loading":_vm.isLoading,"filterable":_vm.filterable,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"clearable":_vm.clearable,"multiple":_vm.multiple},on:{"update:loading":function($event){_vm.isLoading=$event},"search":_vm.onSearch,"input":function (val) {
        _vm.$emit('input', val);
      },"option:selected":function (val) {
        this$1.hasError = false;
        _vm.$emit('selected', val);
      },"option:deselected":function (val) {
        _vm.$emit('deselected', val);
      }},scopedSlots:_vm._u([{key:"search",fn:function(ref){
      var attributes = ref.attributes;
      var events = ref.events;
return [_c('input',_vm._g(_vm._b({staticClass:"vs__search"},'input',attributes,false),events))]}},{key:"open-indicator",fn:function(ref){
      var attributes = ref.attributes;
return [_c('span',_vm._b({},'span',attributes,false),[_c('i',{staticClass:"px-1 fas fa-caret-down"})])]}},{key:"selected-option",fn:function(option){return [_vm._t("selected-option",null,null,option)]}},{key:"option",fn:function(option){return [_vm._t("option",null,null,option)]}},{key:"no-options",fn:function(){return [_vm._t("no-options")]},proxy:true},{key:"list-footer",fn:function(){return [_vm._t("list-footer")]},proxy:true}],null,true)},'v-select',_vm.$attrs,false)),(_vm.hasError)?_c('span',{staticClass:"errorMsg"},[_vm._v(_vm._s(_vm.errorMessage))]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }