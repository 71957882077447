const ERR_CODE = {
    // Misc
    UNKNOWN_ERROR: 0,
    OUTDATED_APP: 1,
    LOGIC_ERROR: 2,
    DUPLICATED_FIELD: 9,

    // Authentication
    AUTHENTICATION_FAILURE: 100,
    JWT_INVALID: 101,
    JWT_NOT_FOUND: 102,
    JWT_EXPIRED: 103
};

export default ERR_CODE;
