import axios from "axios";

export async function reportError(description, title = "Error") {
    let webhookURL = process.env.VUE_APP_ERROR_WEBHOOK;
    let message = `
    **${title}**\n${description}
    `;

    delete axios.defaults.headers.common["agent-cpanel-version"];
    delete axios.defaults.headers.common["admin-cpanel-version"];
    delete axios.defaults.headers.common["pms-cpanel-version"];
    delete axios.defaults.headers.common["banker-cpanel-version"];
    delete axios.defaults.headers.common["lawyer-cpanel-version"];
    delete axios.defaults.headers.common["designer-cpanel-version"];
    try {
        await axios.post(webhookURL, {
            content: message
        });
    } catch (error) {
        throw error;
    }
}

/* export async function reportError({ userId, content }) {
    const firestoreInstance = this.$config.firebase.firestore();
    const today = Date.now();

    try {
        await firestoreInstance
            .collection("ErrorReports")
            .add({
                user: userId,
                content: JSON.stringify(content),
                createdAt: today,
            });

    } catch (error) {
        throw error;
    }
}; */
