import httpAxios from "@websanova/vue-auth/drivers/http/axios.1.x.js";
import routerVueRouter from "@websanova/vue-auth/dist/drivers/router/vue-router.2.x.js";

let authConfig = {
    auth: {
        request: function (req, token) {
            this.http.setHeaders.call(this, req, {
                Authorization: "Bearer " + token
            });
        },
        response: function (res) {
            return res.data.token;
        }
    },
    rolesKey: "role",
    tokenDefaultKey: "auth_tophillsagency_key",
    refreshTokenName: "auth_tophillsagency_refresh_key",
    stores: ["storage", "cookie"],
    http: httpAxios,
    router: routerVueRouter,
    staySignedIn: true,
    authRedirect: {
        name: "Login"
    },
    forbiddenRedirect: {
        name: "ACN"
    },
    notFoundRedirect: {
        name: "ACN"
    },
    loginData: {
        url: "/api/agency/login",
        method: "POST",
        redirect: { name: "ACN" },
        fetchUser: false,
        staySignedIn: false
    },
    logoutData: {
        redirect: "/login",
        makeRequest: false,
        url: "/api/logout",
        method: "POST"
    },
    fetchData: {
        url: "/api/agency/account?include=rolePermission,agency",
        method: "GET",
        enabled: false
    },
    refreshData: {
        url: "/api/token/refresh",
        method: "POST",
        enabled: false,
        interval: 0
    }
};
export default authConfig;
