import Vue from "vue";

import { jsonToArray, extractStringFromJsonArray, jsonUtils } from "./array";
import { compressImage, setAltPhoto } from "./image";
import {
    isStringEmpty,
    numberFormat,
    numberWithCommas,
    toTitleCase,
    getFullAddress
} from "./string";
import { convertNullIfEmpty } from "./null-check";
import { cleanQueryParam, isFilterEmpty } from "./filter";
import { reportError } from "./error-reporting";

Vue.prototype.$compressImage = compressImage;
Vue.prototype.$setAltPhoto = setAltPhoto;

Vue.prototype.$isStringEmpty = isStringEmpty;
Vue.prototype.$numberFormat = numberFormat;
Vue.prototype.$numberWithCommas = numberWithCommas;
Vue.prototype.$toTitleCase = toTitleCase;
Vue.prototype.$getFullAddress = getFullAddress;

Vue.prototype.$mapJsonToArray = jsonToArray;
Vue.prototype.$extractStringFromArray = extractStringFromJsonArray;
Vue.prototype.$jsonUtils = jsonUtils;
Vue.prototype.$convertNullIfEmpty = convertNullIfEmpty;

Vue.prototype.$cleanQueryParam = cleanQueryParam;
Vue.prototype.$isFilterEmpty = isFilterEmpty;

Vue.prototype.$reportError = reportError;

export default null;
