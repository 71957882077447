<template>
  <div class="fd-tabs">
    <div class="tabs__container" :class="containerClass">
      <button class="left-arrow" @click="scrollLeft">
        <i v-if="isLeftScrollable" class="fas fa-chevron-left"></i>
      </button>
      <div ref="tabs" class="tabs" @scroll="onTabScoll">
        <div
          v-for="(tab, index) in tabs"
          :key="'window' + index"
          class="tab-wrapper"
        >
          <component
            :is="isDragging && isDown ? 'span' : 'router-link'"
            v-if="route && tab.to"
            :to="tab.to"
            class="tab"
          >
            <div class="tab--content">
              <i
                v-if="tab.iconClass"
                class="tab--icon"
                :class="tab.iconClass"
              ></i>
              <p>{{ tab.label ? tab.label : tab.name }}</p>
            </div>
          </component>
          <tab
            v-else
            :class="{ active: value == tab.name }"
            :iconClass="tab.iconClass"
            :label="tab.label"
            :name="tab.name"
            @selectTab="selectTab"
          ></tab>
        </div>
      </div>
      <button class="right-arrow" @click="scrollRight">
        <i v-if="isRightScrollable" class="fas fa-chevron-right"></i>
      </button>
    </div>

    <event-listener
      :class="[
        { 'fd-route-tab': route, 'fd-tab-window': !route },
        tabWindowClass
      ]"
      ref="listener"
      @update="updateTabs"
    >
      <slot></slot>
    </event-listener>
  </div>
</template>

<script>
import EventListener from "./EventListener";
import Tab from "./Tab";

let startX;
let scrollLeft;
let dragSpeed = 0.8;

export default {
  name: "fd-tabs",
  props: {
    value: {
      default: null
    },
    route: {
      type: Boolean,
      default: false
    },
    containerClass: {
      type: String,
      default: ""
    },
    tabWindowClass: {
      type: String,
      default: ""
    }
  },
  components: {
    EventListener,
    Tab
  },
  data() {
    return {
      tabs: [], // all of the tabs,
      isLeftScrollable: false,
      isRightScrollable: false,

      resizeObserver: null,
      isDragging: false,
      isDown: false
    };
  },
  computed: {},
  watch: {
    tabs(newVal) {
      /**
       * Check if tab children existed. Default selects the first tab if no
       * value is set. Get new navigation UI for tab length change.
       */
      if (newVal.length > 0) {
        if (this.route) {
          if (this.value === "" || this.value === null) {
            this.$nextTick(() => {
              this.selectTab(this.tabs[0].name);
            });
          }
        }
        this.$nextTick(function () {
          this.getNavigation();
        });
      }
    }
  },
  created() {},
  mounted() {
    this.initResizeObserver();
    this.initTabs();
    this.initDrag();
  },
  updated() {
    this.$nextTick(function () {
      this.getNavigation();
    });
  },
  beforeDestroy() {
    this.resizeObserver.unobserve(this.$refs.tabs);
    this.destroyData();
    this.removeDragEvent();
  },
  methods: {
    initResizeObserver() {
      let vm = this;
      this.resizeObserver = new ResizeObserver(vm.tabResize);
      this.resizeObserver.observe(vm.$refs.tabs);
    },
    initTabs() {
      this.$nextTick(() => {
        this.tabs = this.getTabs();
        if (!this.route && this.tabs.length > 0) {
          if (this.value == null || this.value == "") {
            this.selectTab(this.tabs[0].name);
          } else {
            this.selectTab(this.value);
          }
        }
      });
    },
    destroyData() {
      if (!this.route) {
        this.$emit("input", null);
      }
      this.tabs = [];
    },
    getTabs() {
      let componentName = this.route ? "fd-route-tab" : "fd-tab-window";
      let tabs = this.$slots.default
        .filter((slot) => {
          return slot.componentOptions.tag == componentName;
        })
        .map((node) => node.componentInstance);

      if (tabs.length < 1) {
        throw `[FdTabs] There is no <${componentName}> found in child`;
      }

      return tabs;
    },
    updateTabs() {
      this.$nextTick(() => {
        this.tabs = this.getTabs();
        if (
          this.tabs.length > 0 &&
          this.tabs.filter((tab) => tab.name == this.value) < 1
        ) {
          this.selectTab(this.tabs[0].name);
        }
      });
    },
    tabResize() {
      this.$nextTick(function () {
        this.getNavigation();
      });
    },
    getNavigation() {
      let tabs = this.$refs.tabs;
      this.isLeftScrollable = tabs.scrollLeft > 0;
      let maxScrollLeft = tabs.scrollWidth - tabs.clientWidth;
      this.isRightScrollable = Math.round(tabs.scrollLeft) < maxScrollLeft;
    },
    selectTab(i) {
      if (!this.isDragging) {
        this.$emit("input", i);

        if (this.value !== i) {
          this.$emit("tabChange", i);
        }

        // loop over all the tabs
        this.tabs.forEach((tab) => {
          tab.isActive = tab.name === i;
        });
      }
    },
    onTabScoll() {
      this.getNavigation();
    },
    scrollLeft() {
      this.$refs.tabs.scrollLeft -= 300;
    },
    scrollRight() {
      this.$refs.tabs.scrollLeft += 300;
    },
    initDrag() {
      const tabs = this.$refs.tabs;

      tabs.addEventListener("mousedown", this.tabMouseDownEvent);
      tabs.addEventListener("mouseup", this.tabMouseUpEvent);
      tabs.addEventListener("mouseleave", this.tabMouseLeaveEvent);
      tabs.addEventListener("mousemove", this.tabMouseMoveEvent);
    },
    removeDragEvent() {
      const tabs = this.$refs.tabs;

      tabs.removeEventListener("mousedown", this.tabMouseDownEvent);
      tabs.removeEventListener("mouseup", this.tabMouseUpEvent);
      tabs.removeEventListener("mouseleave", this.tabMouseLeaveEvent);
      tabs.removeEventListener("mousemove", this.tabMouseMoveEvent);
    },
    tabMouseDownEvent(e) {
      this.isDown = true;
      this.isDragging = false;

      startX = e.pageX - this.$refs.tabs.offsetLeft;
      scrollLeft = this.$refs.tabs.scrollLeft;
    },
    tabMouseUpEvent(e) {
      e.preventDefault();
      this.isDown = false;
      this.isDragging = false;
      this.$refs.tabs.classList.remove("drag-active");
    },
    tabMouseLeaveEvent() {
      this.isDown = false;
      this.isDragging = false;
      this.$refs.tabs.classList.remove("drag-active");
    },
    tabMouseMoveEvent(e) {
      if (!this.isDown) return;
      const x = e.pageX - this.$refs.tabs.offsetLeft;
      let travelledDistance = x - startX;
      // Only trigger drag if movement is more than 5px
      if (travelledDistance > 20 || travelledDistance < -20) {
        this.isDragging = true;
        this.$refs.tabs.classList.add("drag-active");
        e.preventDefault();
        e.stopImmediatePropagation();
        const walk = travelledDistance * dragSpeed;
        this.$refs.tabs.scrollLeft = scrollLeft - walk;
      }
    }
  }
};
</script>

<style lang="scss">
@import "./tabs.scss";
</style>
