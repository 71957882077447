// Compress Image
export async function compressImage(file, quality) {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    const p = await new Promise((resolve, reject) => {
        reader.onload = (e) => {
            const img = new Image();
            img.src = e.target.result;

            img.onload = (evt) => {
                let widths = img.naturalWidth;
                let heights = img.naturalHeight;

                const canvas = document.createElement("canvas");
                canvas.height = heights;
                canvas.width = widths;

                const ctx = canvas.getContext("2d");
                ctx.fillStyle = "#fff";
                ctx.fillRect(0, 0, canvas.width, canvas.height);
                ctx.drawImage(img, 0, 0, widths, heights);

                const changeBackground = ctx.getImageData(
                    0,
                    0,
                    widths,
                    heights
                );

                ctx.putImageData(changeBackground, 0, 0);

                const data = ctx.canvas.toDataURL("image/jpeg", quality || 0.8);

                var arr = data.split(","),
                    bstr = atob(arr[1]),
                    n = bstr.length,
                    u8arr = new Uint8Array(n);

                while (n--) {
                    if (n >= 0) {
                        u8arr[n] = bstr.charCodeAt(n);
                    } else {
                        break;
                    }
                }

                resolve(
                    new File(
                        [u8arr],
                        Math.random() * 100 * (Math.random() * 100) + ".jpg",
                        { type: "image/jpeg" }
                    )
                );
            };
        };
    });

    return p;
}

// Set Alt Photo
export function setAltPhoto(event) {
    event.target.src = require("@/assets/image/placeholder/no-img.png");
}

// Set Alt Photo
export function setDefaultAvatar(event) {
    event.target.src = require("@/assets/image/placeholder/default-avatar.svg");
}

export function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
}

export async function fileToDataURL(file) {
    return await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(`fileToDataURL():  ${error}`);
    });
}
