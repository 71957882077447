import axios from "axios";

export default {
  namespaced: true,
  state: () => {
    return {};
  },
  getters: {},
  mutations: {},
  actions: {
    async getAllPartnerships(
      { commit },
      { queries = {}, page = 1, perPage = 15 }
    ) {
      try {
        let include = "partnershipFrom,partnershipWith,property";

        let response = await axios.get(
          `${this._vm.$agencyAPI}/my/partnerships/property` +
          `?page=${page}&limit=${perPage}&include=${include}`,
          { params: queries }
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    async getPartnership({ commit }, { id }) {
      let include = "";

      try {
        let response = await axios.get(
          `${this._vm.$agencyAPI}/my/partnerships/${id}?include=${include}`
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    async getPartnershipProperty({ commit }, { id }) {
      let include = "agencyUser";

      try {
        let response = await axios.get(
          `${this._vm.$commonAPI}/properties/${id}?include=${include}`
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    async getPartnerList({ commit }, { queries = {}, id }) {
      try {
        let response = await axios.get(
          `${this._vm.$agencyAPI}/my/partnerships/property/${id}`
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    async updatePartnership({ commit }, { id, payload }) {
      try {
        const request = await axios.put(
          `${this._vm.$agencyAPI}/my/partnerships/${id}`,
          payload
        );

        return request.data;
      } catch (error) {
        throw error;
      }
    },
    async kickPartnership({ commit }, { id }) {
      try {
        const request = await axios.delete(
          `${this._vm.$agencyAPI}/my/partnerships/${id}/remove`
        );

        return request.data;
      } catch (error) {
        throw error;
      }
    },
    async quitPartnership({ commit }, { id }) {
      try {
        const request = await axios.delete(
          `${this._vm.$agencyAPI}/my/partnerships/property/${id}/withdraw`
        );

        return request.data;
      } catch (error) {
        throw error;
      }
    },
    async getPartnershipDiscussion({ commit }, { id }) {
      let include = "property";

      try {
        let response = await axios.get(
          `${this._vm.$agencyAPI}/my/partnerships/property/${id}/public-discussion`
        );

        return response.data;
      } catch (error) {
        throw error;
      }
    },
    async createPartnershipDiscussion({ commit }, { id, payload }) {
      try {
        const request = await axios.post(
          `${this._vm.$agencyAPI}/my/partnerships/property/${id}/public-discussion`,
          payload
        );

        return request.data;
      } catch (error) {
        throw error;
      }
    },
    async getPartnershipPrivateDiscussion({ commit }, { id }) {
      let include = "property";

      try {
        let response = await axios.get(
          `${this._vm.$agencyAPI}/my/partnerships/${id}/private-discussion`
        );

        return response.data;
      } catch (error) {
        throw error;
      }
    },
    async createPartnershipPrivateDiscussion({ commit }, { id, payload }) {
      try {
        const request = await axios.post(
          `${this._vm.$agencyAPI}/my/partnerships/${id}/private-discussion`,
          payload
        );

        return request.data;
      } catch (error) {
        throw error;
      }
    },
    async uploadFile({ commit }, { id, payload }) {
      try {
        const request = await axios.post(
          `${this._vm.$commonAPI}/file-uploader/file`,
          payload
        );

        return request.data;
      } catch (error) {
        throw error;
      }
    },
    async pushNotification({ commit }, payload) {
      try {
        await axios.post(
          `${this._vm.$agencyAPI}/fcm/chat/push`,
          payload
        );
      } catch (error) {
        throw error;
      }

      return false;
    },
  }
};
