import axios from "axios";
import propertyStatus from "@/enums/propertyStatus";

export default {
    namespaced: true,
    state: () => {
        return {};
    },
    getters: {},
    mutations: {},
    actions: {
        async getAllProperties(
            { commit },
            { queries = {}, page = 1, perPage = 16 }
        ) {
            try {
                let include = "agencyUser";
                let options = {
                    allowPartnership: true,
                    status: propertyStatus.ACTIVE
                };
                Object.assign(queries, options);

                let response = await axios.get(
                    `${this._vm.$agencyAPI}/request-to-partnership/available-property` +
                        `?page=${page}&limit=${perPage}&include=${include}`,
                    { params: queries }
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        async getProperty({ commit }, { id }) {
            try {
                let include =
                    "propertyFacilities,propertyAmenities,propertyTags,propertyCustomCategories,agencyUser,propertyAuction";

                const response = await axios.get(
                    `${this._vm.$commonAPI}/properties/${id}?include=${include}`
                );

                return response.data;
            } catch (error) {
                throw error;
            }
        },
        async sendRequest({ commit }, id) {
            try {
                const response = await axios.post(
                    `${this._vm.$agencyAPI}/request-to-partnership/send-request/property/${id}`
                );
                return response;
            } catch (error) {
                throw error;
            }
        }
    }
};
