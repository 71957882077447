<template>
  <div class="fd-form-section row" :class="{ bordered: bordered, card: card }">
    <span class="col-12 title">{{ title }}</span>
    <slot></slot>
  </div>
</template>

<script>
export default {
  components: {},
  mixins: [],
  props: {
    title: {
      type: String,
      default: "Title"
    },
    bordered: {
      type: Boolean,
      default: false
    },
    card: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {};
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss">
.fd-form-section {
  margin-bottom: 30px;
  width: 100%;

  .title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 15px;
    color: #505050;
  }

  &.bordered {
    border: solid 1px #c6c6c6;
    border-radius: 5px;
  }
  &.card {
    border-radius: 5px;
    box-shadow: 0px 2px 5px #00000030;
  }

  hr {
    border: 0;
    height: 1px;
    background: #333;
    background-image: linear-gradient(to right, #ddd, #ccc, #ddd);
  }
}
</style>
