<template>
  <div
    class="fd-v-select fd-validation"
    :class="{ error: hasError, 'shake-horizontal': hasError }"
  >
    <label class="label">
      {{ label }}
      <span style="color: red" v-if="isRequired && label != null">*</span>
    </label>

    <v-select
      :value="value"
      :options="options"
      :getOptionLabel="optionLabel"
      :getOptionKey="optionKey"
      :reduce="optionValue"
      :loading.sync="isLoading"
      @search="onSearch"
      :filterable="filterable"
      @input="
        (val) => {
          $emit('input', val);
        }
      "
      @option:selected="
        (val) => {
          this.hasError = false;
          $emit('selected', val);
        }
      "
      @option:deselected="
        (val) => {
          $emit('deselected', val);
        }
      "
      :placeholder="placeholder"
      :disabled="disabled"
      :clearable="clearable"
      :multiple="multiple"
      v-bind="$attrs"
    >
      <template #search="{ attributes, events }">
        <input class="vs__search" v-bind="attributes" v-on="events" />
      </template>
      <template #open-indicator="{ attributes }">
        <span v-bind="attributes">
          <i class="px-1 fas fa-caret-down"></i>
        </span>
      </template>
      <template #selected-option="option">
        <slot name="selected-option" v-bind="option"></slot>
      </template>
      <template #option="option">
        <slot name="option" v-bind="option"></slot>
      </template>
      <template #no-options>
        <slot name="no-options"></slot>
      </template>
      <template #list-footer>
        <slot name="list-footer"></slot>
      </template>
    </v-select>

    <!-- Validation error message -->
    <span v-if="hasError" class="errorMsg">{{ errorMessage }}</span>
  </div>
</template>

<script>
import VSelect from "vue-select";
import "vue-select/dist/vue-select.css";

import ValidationMixin from "./Validator/mixin/validatorMixin";

export default {
  components: {
    VSelect
  },
  mixins: [ValidationMixin],
  props: {
    value: {
      required: true
    },
    label: {
      type: String
    },
    options: {
      type: Array,
      required: true,
      default: () => {
        [];
      }
    },
    selectedLabel: {
      type: String,
      default: "name"
    },
    optionLabel: {
      type: Function,
      default: (option) => option.name
    },
    optionKey: {
      type: Function,
      default: (option) => option.id
    },
    optionValue: {
      type: Function,
      default: (option) => option
    },
    placeholder: {
      type: String
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    filterable: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {};
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    onSearch(search, loading) {
      this.$emit("search", search, loading);
    }
  }
};
</script>

<style lang="scss">
.fd-v-select {
  $font-size: 14px;

  .vs__dropdown-option {
    color: #5f5f5f;
    font-size: $font-size;
    font-weight: normal;
  }

  .vs__selected {
    color: #5f5f5f;
    font-size: $font-size;
    font-weight: normal;
  }

  .vs__dropdown-option--highlight {
    background: $color-main;
    color: white;
  }
  .vs__selected-options {
    padding: 2px 12px;
  }

  .vs__clear,
  .vs__open-indicator {
    fill: #5f5f5f;
  }

  .vs__search {
    font-size: $font-size;
  }

  .vs--disabled .vs__dropdown-toggle {
    color: #5f5f5f;
    border: solid 1px #ddd;
  }

  #star {
    padding: 0 4px;
    color: red;
  }

  &.error {
    $errorColor: #db4141;
    .v-select .vs__dropdown-toggle {
      border: 2px solid $errorColor;
    }
    .errorMsg {
      color: $errorColor;
    }
  }
}
</style>
