const propertyStatus = {
    ACTIVE: "Active",
    INACTIVE: "Inactive",
    SOLD: "Sold",
    DEALT_BY_OTHER: "Dealt By Other",
    // DELETED: "Deleted",
    RENT_OUT: "Rent Out"
    // CONFIRMED: "Confirmed",
    // CLOSED: "Closed"
};

export default propertyStatus;
