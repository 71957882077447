// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from "firebase/app";
// If you are using v7 or any earlier version of the JS SDK, you should import firebase using namespace import
// import * as firebase from "firebase/app"

import messaging from "firebase/messaging";
import firestore from 'firebase/firestore';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
var firebaseConfig = {
    apiKey: "AIzaSyD32H0gglsIsLq-w19BUAY8svs1zqySqqM",
    authDomain: "top-hills-app.firebaseapp.com",
    projectId: "top-hills-app",
    storageBucket: "top-hills-app.appspot.com",
    messagingSenderId: "489000192280",
    appId: "1:489000192280:web:6ac56f4d8e8e4cc061366d",
    measurementId: "G-1DJCJS05CW"
};
// Initialize Firebase
firebase.default.initializeApp(firebaseConfig);

if (firebase.messaging.isSupported()) {
    firebase.messaging();
}
firebase.firestore();

export default firebase;