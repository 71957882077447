import ConfirmModal from "./ConfirmModal.vue";

export default {
  install: (Vue, options) => {
    Vue.component("confirm-modal", ConfirmModal);

    Vue.prototype.$confirm = function (
      options = {
        title: "",
        message: "",
        type: "basic",
        cancelText: "Cancel",
        confirmText: "Confirm",
        showCancel: true,
        persistent: false,
      }
    ) {
      let message = options.message;
      let title = options.title;
      let type = options.type;
      let persistent = options.persistent;
      let showCancel = options.showCancel;
      let cancelText = options.cancelText;
      let confirmText = options.confirmText;

      return new Promise((resolve, reject) => {
        const dialog = new Vue({
          methods: {
            closeModal() {
              return function () {
                dialog.$refs.confirmModal.close().then(() => {
                  dialog.$destroy();
                  dialog.$el.remove();
                });
              };
            },
            confirmed(fn, arg) {
              return function () {
                fn(arg);
                dialog.$refs.confirmModal.close().then(() => {
                  dialog.$destroy();
                  dialog.$el.remove();
                });
              };
            },
            canceled(fn, arg) {
              return function () {
                fn(arg);
                dialog.$refs.confirmModal.close().then(() => {
                  dialog.$destroy();
                  dialog.$el.remove();
                });
              };
            }
          },
          render(h) {
            return h(ConfirmModal, {
              ref: "confirmModal",
              props: {
                title,
                message,
                type,
                persistent,
                cancelText,
                confirmText,
                showCancel
              },
              on: {
                close: this.closeModal(),
                confirm: this.confirmed(resolve, true),
                cancel: this.canceled(resolve, false)
              }
            });
          }
        }).$mount();
        document.body.appendChild(dialog.$el);
        dialog.$refs.confirmModal.open();
      });
    };
  }
};
