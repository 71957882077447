import axios from "axios";

export default {
  namespaced: true,
  state: () => {
    return {};
  },
  getters: {},
  mutations: {},
  actions: {
    // ======================== Main CRUD  ========================
    async getAgencyProfile({ commit }) {
      try {
        let include = "";

        let response = await axios.get(`${this._vm.$agencyAPI}/agency-profile`);
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    async updateAgencyProfile({ commit }, { payload }) {
      try {
        const request = await axios.put(
          `${this._vm.$agencyAPI}/agency-profile`,
          payload
        );

        return request.data;
      } catch (error) {
        throw error;
      }
    }
  }
};
