<template>
  <div class="tab-bar">
    <div class="tabs">
      <button
        :class="currentTab == index ? 'tab active' : 'tab'"
        v-for="(btn, index) in tabs"
        :key="'window' + index"
        @click="changeWindow(index)"
      >
        <p>{{ btn }}</p>
      </button>
    </div>
    <div class="tab-window">
      <slot></slot>
    </div>
  </div>
</template>

<script>
var glob;

export default {
  name: "tab-bar",
  props: {
    pageTitle: String,
    currentTab: Number,
    tabs: Array
  },
  data() {
    return {
      windows: []
    };
  },
  mounted() {
    glob = this;

    setTimeout(() => {
      glob.changeWindow(0);
    }, 1);
  },
  methods: {
    changeWindow(ind) {
      if (this.currentTab !== ind) {
        this.$emit("tabChange", ind);
      }
    }
  }
};
</script>

<style lang="scss">
.tab-bar {
  .tabs {
    @include flex(row, flex-start, flex-end);
    overflow-x: auto;
    &::-webkit-scrollbar {
      height: 4px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
    .tab {
      background-color: #fafafa;
      border-radius: 0;
      box-shadow: none;
      max-width: 150px;
      width: 100%;
      padding: 8px 12px;
      outline: none;
      &.active {
        border-top: solid 3px $color-main;
        box-shadow: 0px 5px 8px #00000025;
        background-color: white;
        z-index: 1;
        p {
          color: $color-1;
        }
      }
      p {
        white-space: nowrap;
        font-size: 14px;
        color: lighten($color-1, 40);
      }
    }
  }
  .tab-window {
    box-shadow: 0 2px 5px rgba(black, 0.16), 0 5px 12px rgba(black, 0.12);
    background-color: white;
  }

  @media #{$breakpoint-down-sm} {
    .tabs {
      &::-webkit-scrollbar {
        height: 2px;
      }
    }
  }
}
</style>
